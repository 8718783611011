import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Space, Typography } from 'antd';

import styles from './entry-pages-layout.module.css';

import { ViewAnswerModal } from '~/components/view-answer-modal';
import { ENTER } from '~/constants/enter';
import { ERROR_TEXTS } from '~/constants/modal';
import { PluginError } from '~/constants/plugin';
import { stepName } from '~/constants/step-name';
import { LoaderContextProvider } from '~/contexts';
import { useAppDispatch } from '~/hooks';
import { useCertificateAuthorization } from '~/hooks/use-certificates-authorization';
import { CustomModal } from '~/shared/custom-modal';
import { LogoBlock } from '~/shared/logo';
import { EnterOutletContextType } from '~/shared/types';
import { setPopupAlert } from '~/store/slice/alert/alert-slice';
import { StepParams } from '~/types/router';
import { createAlertError } from '~/utils/create-alert-error';

export const EntryPagesLayout = () => {
    const dispatch = useAppDispatch();

    const {
        authorizationByCertificateHandler,
        isLoadingInAuthByCert,
        isErrorInAuthByCert,
        isOpenAnswerModal,
        setIsOpenAnswerModal,
        answerForModal,
        isDisabledCards,
    } = useCertificateAuthorization();

    const outletContextValue: EnterOutletContextType = {
        isLoadingInAuthByCert,
        isDisabledCards,
        authorizationByCertificateHandler,
    };

    const { stepName: enterStep } = useParams<StepParams>();

    const pageTitle = enterStep === stepName.one ? ENTER.title : ENTER.titleCertificate;

    useEffect(() => {
        if (answerForModal.title === ERROR_TEXTS[PluginError.cancel]) {
            dispatch(setPopupAlert(createAlertError(answerForModal.text).alert));
        }
    }, [answerForModal, dispatch]);

    return (
        <LoaderContextProvider>
            <div className={styles.enterContent}>
                <div className={styles.logoBlock}>
                    <LogoBlock type='white' />
                </div>
                <div className={styles.enterContentBlock}>
                    <Space
                        direction='vertical'
                        align='center'
                        size={16}
                        className={styles.enterBlock}
                    >
                        <Typography.Title level={2} className={styles.enterContentTitle}>
                            {pageTitle}
                        </Typography.Title>
                        <Outlet context={outletContextValue} />
                    </Space>
                </div>
                {answerForModal.title !== ERROR_TEXTS[PluginError.cancel] && (
                    <CustomModal
                        open={isOpenAnswerModal}
                        setOpenModal={setIsOpenAnswerModal}
                        isClose={isOpenAnswerModal}
                        footer={null}
                    >
                        <ViewAnswerModal
                            isError={isErrorInAuthByCert || answerForModal.isError}
                            title={answerForModal.title}
                            text={answerForModal.text}
                        />
                    </CustomModal>
                )}
            </div>
        </LoaderContextProvider>
    );
};

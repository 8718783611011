import { createHashRouter } from 'react-router-dom';

import { authRoute } from './routes/auth';
import { privateRoomRoute } from './routes/private-room';

import { AppLayout } from '~/layouts/app-layout';
import { NotFound } from '~/pages/not-found';

export const AppRouter = createHashRouter([
    {
        element: <AppLayout />,
        children: [authRoute, privateRoomRoute],
    },
    {
        path: '*',
        element: <NotFound />,
    },
]);

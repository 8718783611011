import { api } from '../api';
import { API } from '../constants/api';
import { transformErrorWithAlert } from '../helpers/transform-error-with-alert';

import { AlertMessage } from '~/constants/alert-message';
import { PrintTemplateType } from '~/constants/certificates';
import {
    CheckTemplateTypeResponse,
    InitPrint,
    TemplatesResponse,
} from '~/rtk-queries/types/template';
import { CommonResponse } from '~/types/common';

export const template = api.injectEndpoints({
    endpoints: (builder) => ({
        getTemplate: builder.query<CommonResponse<TemplatesResponse>, number | void>({
            query: (id) => ({
                url: API.template + (id ? `/${id}` : ''),
                method: 'GET',
            }),
            transformErrorResponse: (response) =>
                transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
        }),
        checkTemplateType: builder.query<CheckTemplateTypeResponse, number>({
            query: (folderId) => ({
                url: `${API.checkTemplateType}/${folderId}`,
                method: 'GET',
            }),
            transformErrorResponse: (response) =>
                transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
        }),

        getPrintedTemplate: builder.query<unknown, PrintTemplateType>({
            query: (id) => ({
                url: `${API.printedTemplate}/${id}`,
                method: 'GET',
                headers: {
                    Accept: 'text/html',
                },
                responseHandler: (response) => response.text(),
            }),
            transformErrorResponse: (response) =>
                transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
        }),
        getInitForPrint: builder.query<InitPrint, void>({
            query: () => ({
                url: `${API.printedTemplate}/get-init-script`,
                method: 'GET',
            }),
            transformErrorResponse: (response) =>
                transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
        }),
    }),
});

export const {
    useGetTemplateQuery,
    useLazyGetTemplateQuery,
    useCheckTemplateTypeQuery,
    useLazyGetPrintedTemplateQuery,
    useLazyGetInitForPrintQuery,
} = template;

import { useCallback } from 'react';

import { useCheckProtectedMedia } from './use-check-protected-media';
import { usePluginChecker } from './use-plugin-checker';

import { ERROR_TEXTS, ModalTextVariant, ModalType } from '~/constants/modal';
import { PluginError } from '~/constants/plugin';
import { useAppDispatch } from '~/hooks';
import { useApplyRequestMutation } from '~/rtk-queries/endpoints/certificates-list';
import { useLazyGetTemplateQuery } from '~/rtk-queries/endpoints/template';
import { TemplatesResponse } from '~/rtk-queries/types/template';
import { setPopupAlert } from '~/store/slice/alert/alert-slice';
import { closeModal, openModal } from '~/store/slice/modal/modal-slice';
import { createAlertError } from '~/utils/create-alert-error';
import { isCertificatePreApplication } from '~/utils/is-certificate-variant';
import { createReaderFilter } from '~/utils/plugin/create-reader-filter';
import { generateKeysUtil } from '~/utils/plugin/generate-keys-util';
import { Certificate } from '~/utils/transform-certificates-data/type';

type useGenerateKeysType = {
    currentCertificate: Certificate;
};

export const useGenerateKeys = ({ currentCertificate }: useGenerateKeysType) => {
    const dispatch = useAppDispatch();
    const { checkExtension } = usePluginChecker();
    const { checkProtectedMediaCondition } = useCheckProtectedMedia();

    const [
        applyRequest,
        {
            isSuccess: isSuccessApplyRequest,
            isLoading: isLoadingApplyRequest,
            reset: resetApplyRequest,
        },
    ] = useApplyRequestMutation();
    const [getTemplateData, { isFetching: isFetchingTemplate }] = useLazyGetTemplateQuery();

    const openGenerateKeysModal = async () => {
        const isExtensionRun = await checkExtension();

        if (!isExtensionRun) return;
        dispatch(
            openModal({
                text: ModalTextVariant.generateKeys,
                isError: false,
                type: ModalType.generateKeys,
                title: '',
            }),
        );
    };

    const createAndSubmitRequest = useCallback(
        async (data?: TemplatesResponse) => {
            if (!data || !isCertificatePreApplication(currentCertificate)) return;
            const rawRequest = await generateKeysUtil(
                data.templates[0],
                currentCertificate.distinguishedName,
            );

            if (rawRequest.isError) {
                if (rawRequest.title === ERROR_TEXTS[PluginError.cancel]) {
                    dispatch(setPopupAlert(createAlertError(rawRequest.text).alert));
                } else {
                    dispatch(
                        openModal({
                            text: rawRequest.text,
                            isError: rawRequest.isError,
                            type: ModalType.viewAnswer,
                            title: rawRequest.title,
                        }),
                    );
                }
            } else {
                applyRequest({
                    rawRequest: rawRequest.text,
                    requestId: currentCertificate.lkRequestId,
                });
            }
        },
        [applyRequest, currentCertificate, dispatch],
    );

    const fetchTemplateAndApproveWithFilter = async (
        isNeededCheckMedia: boolean,
        readers: string[] = [],
    ) => {
        if (!isCertificatePreApplication(currentCertificate)) {
            return;
        }
        await createReaderFilter(isNeededCheckMedia, readers);
        const { data } = await getTemplateData(currentCertificate.templateId);

        createAndSubmitRequest(data);
    };

    const checkCarriers = async (isNeededCheckMedia: boolean) => {
        if (isNeededCheckMedia) {
            const { existsProtected, includeUniques } = await checkProtectedMediaCondition();

            if (!existsProtected) {
                dispatch(
                    openModal({
                        isError: true,
                        text: `${ModalTextVariant.protectedMedia} ${includeUniques?.join(', ')}`,
                        title: '',
                        type: ModalType.viewAnswer,
                    }),
                );

                return;
            }
            await fetchTemplateAndApproveWithFilter(isNeededCheckMedia, includeUniques);
        } else {
            await fetchTemplateAndApproveWithFilter(isNeededCheckMedia);
        }
    };

    const generateKeys = async () => {
        const isExtensionRun = await checkExtension();

        if (isExtensionRun && isCertificatePreApplication(currentCertificate)) {
            dispatch(closeModal());
            checkCarriers(!!currentCertificate.isProtectedMedia);
        }
    };

    return {
        openGenerateKeysModal,
        generateKeys,
        isSuccessApplyRequest,
        isLoadingApplyRequest,
        resetApplyRequest,
        isFetchingTemplate,
    };
};

import { useNavigate } from 'react-router-dom';
import { FileTextOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd';

import { ApplicationTemplateProps } from './types';
import { useApplicationModal } from './use-application-modal';
import { useFormSearchQuery } from './use-form-search-query';

import { ROUTES } from '~/app-router/routes';
import { BASE_PARAMS } from '~/components/certificates-table/constants/general';
import { BUTTON_TEXT } from '~/constants/button';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { ModalTextVariant } from '~/constants/modal';
import { useCreateDraftRequestMutation } from '~/rtk-queries/endpoints/draft';
import { CreateDraftBody } from '~/rtk-queries/types/certificates';
import { CertificateForm } from '~/types/application-tab/certificate-form';
import { ValidateErrorEntity } from '~/types/common';
import { createDraftSuccessText } from '~/utils/application-tab/create-success-text';
import { prepareCreateRequestBody } from '~/utils/application-tab/prepare-create-request-body';
import { isNotEmptyArray } from '~/utils/is-not-empty-array';
import { removeLocalStorageItem } from '~/utils/local-storage';

export const useUpdateDraft = (
    form: FormInstance<CertificateForm>,
    enabledAttributeMap: Record<string, boolean>,
    { authorityName, displayName }: ApplicationTemplateProps,
) => {
    const navigate = useNavigate();
    const { openApplicationModal } = useApplicationModal();
    const { folderId, templateId, draftId } = useFormSearchQuery();

    const [postCreateDraftRequest, { isLoading: isLoadingDraftCertificate }] =
        useCreateDraftRequestMutation();

    const updateDraft = async () => {
        try {
            const formValues = form.getFieldsValue();

            const body: CreateDraftBody = {
                authorityName,
                requestId: draftId,
                folderId,
                templateId,
                innUl: null,
                kpp: null,
                userData: prepareCreateRequestBody(formValues, enabledAttributeMap),
            };

            await postCreateDraftRequest(body).unwrap();
            removeLocalStorageItem(LOCAL_STORAGE.filtersForCertificates);

            openApplicationModal({
                title: ModalTextVariant.createDraft,
                content: createDraftSuccessText(displayName ?? '', !!draftId),
                icon: <FileTextOutlined />,
                okText: BUTTON_TEXT.ok.toLocaleUpperCase(),
            });

            navigate(`${ROUTES.privateRoom}${ROUTES.certificateTable}${BASE_PARAMS}`);
        } catch (error) {
            const errorObj = error as ValidateErrorEntity<CertificateForm>;

            if (
                isNotEmptyArray(errorObj?.errorFields) &&
                isNotEmptyArray(errorObj.errorFields[0].name)
            ) {
                form.scrollToField(errorObj.errorFields[0].name[0], {
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }
    };

    return {
        updateDraft,
        isLoadingDraftCertificate,
    };
};

export const createReaderFilter = async (
    isNeededCheckMedia: boolean,
    includeUniques?: string[],
) => {
    const { cadesplugin } = window;
    const oAbout = await cadesplugin.CreateObjectAsync('CAdESCOM.About');

    if (isNeededCheckMedia) {
        const readers = includeUniques?.join('|');
        const pattern = `.*(${readers}|${readers?.toUpperCase()}).*`;

        await oAbout.ReaderFilter(cadesplugin.ENABLE_ANY_CARRIER_TYPE, 0, pattern);
    } else {
        await oAbout.ReaderFilter(cadesplugin.ENABLE_ANY_CARRIER_TYPE, 0, '.*');
    }
};

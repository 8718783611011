export const ESIA_REDIRECT = {
    tooltipText: 'Пожалуйста,\u00A0подождите. Личный кабинет сейчас откроется.',
};

export const ESIA_ERROR_CODE_REGEX = /ESIA-\d{6}/;

export const EsiaCodeDescription: { Default: string } & Record<string, string> = {
    Default:
        'Авторизация через Госуслуги недоступна. Попробуйте еще раз или воспользуйтесь другим способом авторизации.',
    'ESIA-007007':
        'Возникла неожиданная ошибка в работе сервиса авторизации, которая привела к невозможности выполнить запрос',
    'ESIA-007008':
        'Сервис авторизации в настоящее время не может выполнить запрос из-за большой нагрузки или технических работ на сервере',
    'ESIA-007019': 'Отсутствует разрешение на доступ',
    'ESIA-007036': 'Учетная запись заблокирована',
    'ESIA-007055':
        'Вход в Личный кабинет осуществляется с неподтвержденной учетной записью. Подтвердите учётную запись на портале Госуслуг и повторите попытку входа. ',
    'ESIA-007194':
        'Запрос области доступа для организации, сотрудником которой пользователь не является',
    'ESIA-007071':
        'Запрос персональных данных по физическим лицам может быть выполнен только с указанием согласий',
    'ESIA-007004':
        'Права доступа на просмотр ваших данных в Госулугах не были предоставлены. Нажмите "Предоставить" на экране подтверждения доступа или воспользуйтесь другим способом авторизации.',
};

import { Suspense, useEffect } from 'react';
import { Await, useLoaderData } from 'react-router-dom';

import { Enter } from '../enter';

import styles from './lazy-enter-page.module.css';

import { CRYPTO_PLUGIN_CHECKED_TEXT } from '~/constants/plugin';
import { AnimatedEllipsis } from '~/shared/animated-ellipsis';
import { CustomSpinner } from '~/shared/custom-spinner';
import { EnterStepsLoaderData } from '~/shared/types';

export const LazyEnterPage = () => {
    const data = useLoaderData() as EnterStepsLoaderData;

    useEffect(() => {
        const handlePageShow = (event: PageTransitionEvent) => {
            if (event.persisted) {
                console.info('Page restored from bfcache. Reloading...');
                window.location.reload();
            }
        };

        window.addEventListener('pageshow', handlePageShow);

        return () => {
            window.removeEventListener('pageshow', handlePageShow);
        };
    }, []);

    return (
        <Suspense
            fallback={
                <CustomSpinner
                    tip={<AnimatedEllipsis title={CRYPTO_PLUGIN_CHECKED_TEXT} />}
                    className={styles.loader}
                    size='large'
                />
            }
        >
            <Await resolve={data.isPluginInstalled}>
                <Enter />
            </Await>
        </Suspense>
    );
};

export const createRequestSuccessText = (templateName: string) =>
    `Данные для выпуска сертификата ${templateName} от\u00A0${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString(
        [],
        { hour: '2-digit', minute: '2-digit' },
    )} (МСК) отправлены на проверку.`;

export const createDraftSuccessText = (templateName: string, exist = false) =>
    `Черновик сертификата ${templateName} от ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString(
        [],
        { hour: '2-digit', minute: '2-digit' },
    )} (МСК) ${exist ? 'обновлен' : 'создан'}.`;

import { joinTitleSerialNumber } from '../join-title-serial-number';
import { transformDateToLocale } from '../transform-date-to-locale';
import { uuidGenerator } from '../uuid-generator';

import { CertificatesStatusInfo } from '~/constants/certificates';
import { Certificate, CertificateVariant } from '~/utils/transform-certificates-data/type';

// Утилита, которая трансфортирует данные для отображения в таблице с перечнем сертификатов

export const transformCertificateData = (certificates: CertificateVariant[]) => {
    const titleHashmap: Record<string, string> = {};

    const transformedCurrentPage = certificates.map((certificateData) => {
        const { lastEditDate, title, serialNumber, status, revokedWhen } = certificateData;
        const { color, titleStatus, actions, documents } = CertificatesStatusInfo[status];

        const transformedTitle = joinTitleSerialNumber(title, serialNumber);

        titleHashmap[title] = transformedTitle;

        const titleForStatus = revokedWhen
            ? `${titleStatus} ${transformDateToLocale(revokedWhen)}`
            : titleStatus;

        const certificate: Certificate = {
            ...certificateData,
            title: transformedTitle,

            status: { color, titleStatus: titleForStatus, statusCode: status },
            lastEditDate: transformDateToLocale(lastEditDate),
            key: uuidGenerator('table-key'),
            actions,
            documents,
        };

        return certificate;
    });

    return { transformedCurrentPage, titleHashmap };
};

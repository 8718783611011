import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

import { alertSelector, setPopupAlert } from '../slice/alert/alert-slice';

import { isAlertWithMessage } from '~/utils/is-alert-with-message';

export const popupAlertsMiddleware: Middleware =
    ({ dispatch, getState }: MiddlewareAPI) =>
    (next) =>
    (action) => {
        if (isRejectedWithValue(action)) {
            const state = getState();

            if (!isAlertWithMessage(action.payload)) return next(action);

            const { alert } = action.payload;
            const popupAlertMessage = action.payload.alert?.message;
            const { alerts } = alertSelector(state);
            const isPopupAlertAlreadyExists = alerts.some(
                ({ message }) => message === popupAlertMessage,
            );
            const isMustShowAlert = popupAlertMessage && !isPopupAlertAlreadyExists;

            if (alert && isMustShowAlert) {
                dispatch(setPopupAlert(alert));
            }
        }

        return next(action);
    };

import { api } from '../api';
import { API, KEEP_DATA_TIME } from '../constants/api';
import { transformErrorWithAlert } from '../helpers/transform-error-with-alert';

import { AlertMessage } from '~/constants/alert-message';
import { Tags } from '~/rtk-queries/constants/tags';
import {
    ApplyCertificateRequest,
    CertificatesListRequest,
    CertificatesListResponse,
    CreateCertificateBody,
    PKIDetailsRequest,
    PKIDetailsResponse,
    RevokeCertificateRequest,
    SendToArchiveParams,
} from '~/rtk-queries/types/certificates';
import { CommonResponse } from '~/types/common';
import { transformCertificateData } from '~/utils/transform-certificates-data/transform-certificates-data';
import { GetCertificatesResponse } from '~/utils/transform-certificates-data/type';
import { transformTitleFilters } from '~/utils/transform-title-filters';

export const certificatesList = api
    .enhanceEndpoints({
        addTagTypes: [Tags.GET_CERTIFICATES_LIST],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getCertificatesList: builder.query<
                CommonResponse<CertificatesListResponse>,
                Partial<CertificatesListRequest>
            >({
                query: (body) => ({
                    url: API.getCertificatesList,
                    method: 'POST',
                    body,
                }),
                transformResponse: ({
                    currentPage,
                    allTitles,
                    ...rest
                }: CommonResponse<GetCertificatesResponse>) => {
                    const { transformedCurrentPage, titleHashmap } =
                        transformCertificateData(currentPage);
                    const transformedFilters = transformTitleFilters(titleHashmap, allTitles);

                    return {
                        ...rest,
                        currentPage: transformedCurrentPage,
                        allTitles: transformedFilters,
                    };
                },
                providesTags: [Tags.GET_CERTIFICATES_LIST],
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
                keepUnusedDataFor: KEEP_DATA_TIME,
            }),

            revokeCertificate: builder.mutation<null, RevokeCertificateRequest>({
                query: (body) => ({
                    url: `${API.revokeCertificateRequest}`,
                    method: 'POST',
                    body,
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
                invalidatesTags: (_, error) => (error ? [] : [Tags.GET_CERTIFICATES_LIST]),
            }),

            createCertificateRequest: builder.mutation<CommonResponse, CreateCertificateBody>({
                query: (body) => ({
                    url: `${API.createCertificateRequest}`,
                    method: 'POST',
                    body,
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
                invalidatesTags: (_, error) => (error ? [] : [Tags.GET_CERTIFICATES_LIST]),
            }),

            applyRequest: builder.mutation<void, ApplyCertificateRequest>({
                query: (data) => ({
                    url: `${API.applyCertificateRequest}`,
                    method: 'POST',
                    body: data,
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
                invalidatesTags: (_, error) => (error ? [] : [Tags.GET_CERTIFICATES_LIST]),
            }),

            sendToArchive: builder.mutation<void, SendToArchiveParams>({
                query: (params) => ({
                    url: `${API.sendToArchive}`,
                    method: 'POST',
                    params,
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
                invalidatesTags: (_, error) => (error ? [] : [Tags.GET_CERTIFICATES_LIST]),
            }),

            unarchive: builder.mutation<void, SendToArchiveParams>({
                query: (params) => ({
                    url: `${API.unarchive}`,
                    method: 'POST',
                    params,
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
                invalidatesTags: (_, error) => (error ? [] : [Tags.GET_CERTIFICATES_LIST]),
            }),

            getCertificatePKIDetails: builder.query<
                CommonResponse<PKIDetailsResponse>,
                PKIDetailsRequest
            >({
                query: (args) => ({
                    url: API.getCertificatePKIDetails,
                    method: 'GET',
                    params: args,
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_REQUEST_DATA),
            }),

            resetCertificateChecks: builder.mutation<void, string>({
                query: (userId) => ({
                    url: API.restartCertificateChecks,
                    method: 'GET',
                    params: { uuid: userId },
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
                invalidatesTags: (_, error) => (error ? [] : [Tags.GET_CERTIFICATES_LIST]),
            }),
        }),
    });

export const {
    useGetCertificatesListQuery,
    useRevokeCertificateMutation,
    useCreateCertificateRequestMutation,
    useApplyRequestMutation,
    useLazyGetCertificatePKIDetailsQuery,
    useSendToArchiveMutation,
    useUnarchiveMutation,
    useResetCertificateChecksMutation,
} = certificatesList;

import { CollapseProps } from 'antd';

import { AdditionalResult } from '../../shared/additional-result';
import { CertificateInfo } from '../../shared/certificate-info';
import { AdditionalInformation } from '../result-block/additional-information/additional-information';
import { MainInfo } from '../result-block/main-info';

import styles from './return-items-for-collapse.module.css';

import { CheckSignatureDocumentResponse } from '~/rtk-queries/types/certificate-verification';

type CollapseItem = Required<CollapseProps>['items'][number];

export const returnItemsForCollapse = (
    fileName: string,
    verifyCertificate?: CheckSignatureDocumentResponse['verificationResult'],
): CollapseProps['items'] => {
    if (!verifyCertificate) return undefined;

    const createItem = (key: string, label: string, children: React.ReactNode): CollapseItem => ({
        key,
        label,
        children,
        showArrow: true,
        headerClass: styles.collapseHeader,
        className: styles.collapseContainer,
    });

    const itemsForCollapse: CollapseItem[] = [];

    if (!verifyCertificate.verificationResults) {
        itemsForCollapse.push(
            createItem(
                'Основная информация',
                'Основная информация',
                <MainInfo
                    fileName={fileName}
                    result={false}
                    message={verifyCertificate.errorDescription ?? ''}
                />,
            ),
        );

        return itemsForCollapse;
    }

    const { signatureInfo, signerCertificateInfo, result, additionalCertificateResult, message } =
        verifyCertificate.verificationResults[0];

    itemsForCollapse.push(
        createItem(
            'Основная информация',
            'Основная информация',
            <MainInfo fileName={fileName} result={result} message={message} />,
        ),
    );

    if (signatureInfo) {
        itemsForCollapse.push(
            createItem(
                'Дополнительная информация о подписи',
                'Дополнительная информация о подписи',
                <AdditionalInformation signatureInfo={signatureInfo} />,
            ),
        );
    }

    if (signerCertificateInfo) {
        itemsForCollapse.push(
            createItem(
                'Информация о сертификате',
                'Информация о сертификате',
                <CertificateInfo info={signerCertificateInfo} result={result} />,
            ),
        );
    }

    additionalCertificateResult?.forEach(({ pluginDescription, errorsList, bResult }) => {
        itemsForCollapse.push(
            createItem(
                pluginDescription,
                pluginDescription,
                <AdditionalResult errors={errorsList} result={bResult} />,
            ),
        );
    });

    return itemsForCollapse;
};

import { FrontConfigLinksKey, FrontConfigType } from '../get-front-config/type';
import { isErrorPluginWithReason } from '../is-error-plugin-with-reason';

import { checkCryptoInstalled } from './check-crypto-installed';
import { ERROR_REASONS } from './constants';

import {
    BrauserName,
    LINK_CADES_PLAGIN,
    LINK_CSP,
    LINK_PLATFORM_INSTRUCTIONS,
    PlatformName,
} from '~/constants/link-cades-plugin';
import { PluginError } from '~/constants/plugin';
import { CadesErrorType } from '~/types/plugin';

type CheckCadesExtensionProps = {
    setCadesStatus: React.Dispatch<React.SetStateAction<boolean>>;
    setExtentionStatus: React.Dispatch<React.SetStateAction<boolean>>;
    setCspStatus: React.Dispatch<React.SetStateAction<boolean>>;
    setBrowserLink: React.Dispatch<React.SetStateAction<string>>;
    setPlatformLink: React.Dispatch<React.SetStateAction<string>>;
    setCspLink: React.Dispatch<React.SetStateAction<string>>;
    setErrorType: React.Dispatch<React.SetStateAction<CadesErrorType | null>>;
    setLoadCadesPlugin: React.Dispatch<React.SetStateAction<boolean>>;
    frontConfig?: FrontConfigType;
};

export const checkCadesExtension = ({
    setCadesStatus,
    setExtentionStatus,
    setCspStatus,
    setBrowserLink,
    setPlatformLink,
    setCspLink,
    setErrorType,
    setLoadCadesPlugin,
    frontConfig,
}: CheckCadesExtensionProps) => {
    const { cadesplugin } = window;

    if (!cadesplugin) return;
    const browserLink = {
        [BrauserName.chrome]: frontConfig
            ? frontConfig.links[FrontConfigLinksKey.ExtentionLinkChrome]
            : LINK_CADES_PLAGIN[BrauserName.chrome],
        [BrauserName.edg]: frontConfig
            ? frontConfig.links[FrontConfigLinksKey.ExtentionLinkEdge]
            : LINK_CADES_PLAGIN[BrauserName.edg],
        [BrauserName.yaBrowser]: frontConfig
            ? frontConfig.links[FrontConfigLinksKey.ExtentionLinkYandex]
            : LINK_CADES_PLAGIN[BrauserName.yaBrowser],
        [BrauserName.opera]: frontConfig
            ? frontConfig.links[FrontConfigLinksKey.ExtentionLinkOpera]
            : LINK_CADES_PLAGIN[BrauserName.opera],
        [BrauserName.firefox]: frontConfig
            ? frontConfig.links[FrontConfigLinksKey.ExtentionLinkFirefox]
            : LINK_CADES_PLAGIN[BrauserName.firefox],
        [BrauserName.safari]: frontConfig
            ? frontConfig.links[FrontConfigLinksKey.ExtentionLinkDefault]
            : LINK_CADES_PLAGIN[BrauserName.safari],
        [BrauserName.ie]: frontConfig
            ? frontConfig.links[FrontConfigLinksKey.ExtentionLinkIe]
            : LINK_CADES_PLAGIN[BrauserName.ie],
        [BrauserName.msie]: frontConfig
            ? frontConfig.links[FrontConfigLinksKey.ExtentionLinkMsie]
            : LINK_CADES_PLAGIN[BrauserName.msie],
    };

    setBrowserLink(
        browserLink[cadesplugin.browserSpecs.name as BrauserName] ??
            (frontConfig
                ? frontConfig.links[FrontConfigLinksKey.ExtentionLinkDefault]
                : LINK_CADES_PLAGIN.default),
    );

    setCspLink(frontConfig ? frontConfig.links[FrontConfigLinksKey.LoadingCSP] : LINK_CSP);

    const PlatformLink = {
        [PlatformName.windows]: frontConfig
            ? frontConfig.links[FrontConfigLinksKey.InstructionLinkWindows]
            : LINK_PLATFORM_INSTRUCTIONS[PlatformName.windows],
        [PlatformName.macOS]: frontConfig
            ? frontConfig.links[FrontConfigLinksKey.InstructionLinkMacOS]
            : LINK_PLATFORM_INSTRUCTIONS[PlatformName.macOS],
        [PlatformName.aurora]: frontConfig
            ? frontConfig.links[FrontConfigLinksKey.InstructionLinkAurora]
            : LINK_PLATFORM_INSTRUCTIONS[PlatformName.aurora],
        [PlatformName.linux]: frontConfig
            ? frontConfig.links[FrontConfigLinksKey.InstructionLinkLinux]
            : LINK_PLATFORM_INSTRUCTIONS[PlatformName.linux],
    };

    setPlatformLink(
        PlatformLink[cadesplugin.platformCheck() as PlatformName] ??
            (frontConfig
                ? frontConfig.links[FrontConfigLinksKey.InstructionLinkDefault]
                : LINK_PLATFORM_INSTRUCTIONS.default),
    );

    cadesplugin
        .then(() => {
            setExtentionStatus(true);
            setCadesStatus(true);

            return checkCryptoInstalled();
        })
        .then((result: string | boolean) => {
            setCspStatus(Boolean(result));
            if (!result) setErrorType(ERROR_REASONS.CSP);
        })
        .catch((err: unknown) => {
            if (isErrorPluginWithReason(err) && err.reason === ERROR_REASONS.EXTENSION) {
                setExtentionStatus(false);
                setErrorType(ERROR_REASONS.EXTENSION);
            }
            if (isErrorPluginWithReason(err) && err.reason === ERROR_REASONS.PLUGIN) {
                setCadesStatus(false);
                setErrorType(ERROR_REASONS.PLUGIN);
            }
            if (err === PluginError.cspNotInstall) {
                setCspStatus(false);
                setErrorType(ERROR_REASONS.CSP);
            }
        })
        .finally(() => {
            setLoadCadesPlugin(false);
        });
};

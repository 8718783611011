import { useCallback } from 'react';

import { usePluginChecker } from './use-plugin-checker';

import { LifeStatus } from '~/constants/certificates-table';
import { ERROR_TEXTS, ModalType } from '~/constants/modal';
import { PluginError } from '~/constants/plugin';
import { useAppDispatch } from '~/hooks';
import { useLazyGetCertificatePKIDetailsQuery } from '~/rtk-queries/endpoints/certificates-list';
import { PKIDetailsResponse } from '~/rtk-queries/types/certificates';
import { setPopupAlert } from '~/store/slice/alert/alert-slice';
import { openModal } from '~/store/slice/modal/modal-slice';
import { createAlertError } from '~/utils/create-alert-error';
import { isCertificateInstance } from '~/utils/is-certificate-variant';
import { installCertificateUtil } from '~/utils/plugin/install-certificate-util';
import { Certificate } from '~/utils/transform-certificates-data/type';

export const useInstallCertificate = () => {
    const dispatch = useAppDispatch();
    const { checkExtension } = usePluginChecker();

    const [getPKIDetails, { isFetching: isFetchingPKIDetailsInInstall }] =
        useLazyGetCertificatePKIDetailsQuery();

    const installCertificate = useCallback(
        async (data?: PKIDetailsResponse) => {
            const isExtensionRun = await checkExtension();

            if (!isExtensionRun || !data) return;
            const installData = await installCertificateUtil(
                data.certificateDetails.rawCertificate,
            );

            if (installData.title === ERROR_TEXTS[PluginError.cancel]) {
                dispatch(setPopupAlert(createAlertError(installData.text).alert));
            } else {
                dispatch(
                    openModal({
                        text: installData.text,
                        isError: installData.isError,
                        type: ModalType.viewAnswer,
                        title: installData.title,
                    }),
                );
            }
        },
        [checkExtension, dispatch],
    );

    const getDetailsForInstall = async (data: Certificate) => {
        if (data.lifeStatus !== LifeStatus.certificate) return;
        if (isCertificateInstance(data)) {
            const { data: pkiData } = await getPKIDetails({
                certificateId: data?.certificateId,
            });

            installCertificate(pkiData);
        }
    };

    return { getDetailsForInstall, isFetchingPKIDetailsInInstall };
};

import { FC, PropsWithChildren } from 'react';
import { Button, Typography } from 'antd';
import { ButtonProps } from 'antd/lib';

import styles from './menu-item-button.module.css';

type MenuItemButtonProps = PropsWithChildren<ButtonProps> & {
    href?: string;
};

export const MenuItemButton: FC<MenuItemButtonProps> = ({ children, href, ...props }) => {
    if (href) {
        return (
            <a target='_blank' rel='noopener noreferrer nofollow' {...{ href }}>
                <Button className={styles.submenuButton} type='link' size='large' {...props}>
                    <Typography.Text className={styles.submenuButtonLabel}>
                        {children}
                    </Typography.Text>
                </Button>
            </a>
        );
    }

    return (
        <Button className={styles.submenuButton} type='link' size='large' {...props}>
            <Typography.Text className={styles.submenuButtonLabel}>{children}</Typography.Text>
        </Button>
    );
};

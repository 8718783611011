import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    isNavigationDisabled: false,
};

export const appTabSlice = createSlice({
    name: 'apptab-slice',
    initialState,
    reducers: {
        setNavigationDisabled: (state, actions: PayloadAction<boolean>) => {
            state.isNavigationDisabled = actions.payload;
        },
    },
    selectors: { appTabSelector: (state) => state },
});

export type AppTabState = typeof initialState;
export const { setNavigationDisabled } = appTabSlice.actions;
export const { appTabSelector } = appTabSlice.selectors;
export const appTabReducer = appTabSlice.reducer;

import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import styles from './warning-block.module.css';

import { ModalTextVariant } from '~/constants/modal';
import { CadesErrorType } from '~/types/plugin';
import { MODAL_VARIANT_FOR_PLUGIN } from '~/utils/plugin/constants';

type WarningBlockProps = {
    isAbortFetchCertificates: boolean;
    abortedFetchingCertificatesText: string;
    isNeedLink: boolean;
    errorType: CadesErrorType | null;
    installLink: string;
    pluginErrorText: string;
};

export const WarningBlock = ({
    abortedFetchingCertificatesText,
    isAbortFetchCertificates,
    errorType,
    installLink,
    pluginErrorText,
    isNeedLink,
}: WarningBlockProps) => (
    <Fragment>
        <span>{isAbortFetchCertificates ? abortedFetchingCertificatesText : pluginErrorText}</span>
        {isNeedLink && errorType && (
            <Fragment>
                <span>{MODAL_VARIANT_FOR_PLUGIN[errorType].text}</span>
                <Link to={installLink} target='_blank' className={styles.link}>
                    {ModalTextVariant.linkForInstall}
                </Link>
            </Fragment>
        )}
    </Fragment>
);

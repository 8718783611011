const REACT_APP_URL = process.env.REACT_APP_URL ?? 'https://dev.cp.clevertec.ru/api';
const CERTIFICATE = 'Certificate';
const DRAFT = 'Draft';
const AUTH = 'Authentication';
const USER_INFO = 'UserInfo';
const PKI_API = 'PkiApi';
const SVS_API = 'SvsApi';
const FRONT_CONFIG = 'FrontConfig';
const FEEDBACK = 'Feedback';
const TEMPLATE = 'Template';

export const KEEP_DATA_TIME = 5 * 60;

export const API = {
    privateRoomInfo: `${REACT_APP_URL}/`,

    EnsureSession: `${REACT_APP_URL}/${AUTH}/ensure-session`,
    getEsiaLoginUrl: `${REACT_APP_URL}/${AUTH}/get-esia-login-url`,
    loginByCertificate: `${REACT_APP_URL}/${AUTH}/login-by-certificate`,
    loginByEsia: `${REACT_APP_URL}/${AUTH}/login-by-esia`,
    logout: `${REACT_APP_URL}/${AUTH}/logout`,
    logoutEsia: `${REACT_APP_URL}/${AUTH}/esia-logout-url`,
    refreshToken: `${REACT_APP_URL}/${AUTH}/refresh-token`,

    getCertificatesList: `${REACT_APP_URL}/${CERTIFICATE}`,
    getCertificatePKIDetails: `${REACT_APP_URL}/${CERTIFICATE}/get-pki-details`,
    createCertificateRequest: `${REACT_APP_URL}/${CERTIFICATE}/create-request`,
    revokeCertificateRequest: `${REACT_APP_URL}/${CERTIFICATE}/revoke-pki-certificate`,
    applyCertificateRequest: `${REACT_APP_URL}/${CERTIFICATE}/send-request`,
    sendToArchive: `${REACT_APP_URL}/${CERTIFICATE}/archive-pki-certificate`,
    unarchive: `${REACT_APP_URL}/${CERTIFICATE}/unarchive-pki-certificate`,
    restartCertificateChecks: `${REACT_APP_URL}/${CERTIFICATE}/checksRestart`,

    getDraft: `${REACT_APP_URL}/${DRAFT}/get-draft`,
    removeDraft: `${REACT_APP_URL}/${DRAFT}/remove-draft`,
    createDraftRequest: `${REACT_APP_URL}/${DRAFT}/ensure-update-draft`,

    userInfo: `${REACT_APP_URL}/${USER_INFO}`,
    userName: `${REACT_APP_URL}/${USER_INFO}/user-name`,
    countries: `${REACT_APP_URL}/${USER_INFO}/countries`,

    template: `${REACT_APP_URL}/${TEMPLATE}`,
    checkTemplateType: `${REACT_APP_URL}/${TEMPLATE}/info`,

    getPKIUser: `${REACT_APP_URL}/${PKI_API}/user`,
    getPKICertificate: `${REACT_APP_URL}/${PKI_API}/certificate`,
    getPKICertRequest: `${REACT_APP_URL}/${PKI_API}/cert-request`,
    getPKIRevoke: `${REACT_APP_URL}/${PKI_API}/revoke-request`,

    getSvsPolicy: `${REACT_APP_URL}/${SVS_API}/policy`,
    checkVerifyCertificate: `${REACT_APP_URL}/${SVS_API}/verify-certificate`,
    checkSignatureDocuments: `${REACT_APP_URL}/${SVS_API}/verify-signatures`,

    printedTemplate: `${REACT_APP_URL}/PrintTemplate`,
    namePolicy: `${REACT_APP_URL}/NamePolicy`,

    getFrontConfig: `${REACT_APP_URL}/${FRONT_CONFIG}/get-links`,
    getSignOptions: `${REACT_APP_URL}/${FRONT_CONFIG}/get-sign-options`,

    sendFeedback: `${REACT_APP_URL}/${FEEDBACK}/send-feedback`,
} as const;

import { FC, PropsWithChildren } from 'react';
import { Button, ButtonProps } from 'antd';
import classNames from 'classnames';

import styles from './custom-button.module.css';

import { ButtonType } from '~/constants/button';

type ButtonPresentation = 'primary' | 'secondary' | 'outlined';

type CustomButtonProps = ButtonProps & {
    className?: string;
    presentation?: ButtonPresentation;
};
// FIXME: эту хрень надо переделать нормально, класс варианта + доп. класс из родителя чтобы вешался с кастомным CSS, а не 10 000 классов для каждой кнопки
export const CustomButton: FC<PropsWithChildren<CustomButtonProps>> = ({
    className = '',
    children,
    presentation = 'primary',
    ...btnProps
}) => (
    <Button
        htmlType={ButtonType.button}
        className={classNames(styles[className], { [styles[presentation]]: presentation })}
        {...btnProps}
    >
        {children}
    </Button>
);

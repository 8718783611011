import { useRef } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Layout, Tabs } from 'antd';

import { Header } from './components/header/header';
import { PrivateRoomTabItem } from './constants/private-room-tab-item';
import { useApplicationTabModal } from './hooks/use-application-tab-modal';

import styles from './private-room-layout.module.css';

import { ROUTES } from '~/app-router/routes';
import { ContactUs } from '~/components/contact-us';
import { useAppSelector } from '~/hooks';
import { usePrivateRoomUrlParams } from '~/hooks/use-private-room-url-params';
import { appTabSelector } from '~/store/slice/app-tab/app-tab-slice';

const { Content } = Layout;

export const PrivateRoomLayout = () => {
    const tabsSearchQuery = useRef<{ [key: string]: string }>({
        [ROUTES.certificateTable]: '',
        [ROUTES.archive]: '',
    });

    const navigate = useNavigate();
    const location = useLocation();
    const { isNavigationDisabled } = useAppSelector(appTabSelector);
    const { contextHolder, showApplicationModal } = useApplicationTabModal();

    const { activeRootTab, roomUrlParams } = usePrivateRoomUrlParams();

    const handleTabClick = (newTabKey: string) => {
        if (activeRootTab in tabsSearchQuery.current) {
            tabsSearchQuery.current[activeRootTab] = location.search;
        }

        const params = tabsSearchQuery.current[newTabKey] ?? '';

        const shouldOpenApplicationTabModal =
            isNavigationDisabled && roomUrlParams[1] === ROUTES.applicationForm;

        if (shouldOpenApplicationTabModal) {
            showApplicationModal(newTabKey, params);
        } else {
            navigate(`${ROUTES.privateRoom}${newTabKey}${params}`);
        }
    };

    return (
        <Layout className={styles.wrapper}>
            <Header />
            <Content>
                <div className={styles.privateRoomContainer}>
                    <Tabs
                        className={styles.tabs}
                        onTabClick={handleTabClick}
                        items={PrivateRoomTabItem}
                        tabBarGutter={28}
                        defaultActiveKey={ROUTES.certificateTable}
                        activeKey={activeRootTab}
                    />
                    <Outlet />
                    <ContactUs />
                </div>

                {isNavigationDisabled && <div>{contextHolder}</div>}
            </Content>
        </Layout>
    );
};

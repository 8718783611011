import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ModalType } from '~/constants/modal';
import { ModalInfoType } from '~/types/common';

const initialState = {
    isOpenModal: false,
    modalInfo: {
        text: '',
        isError: false,
        type: ModalType.viewAnswer,
        title: '',
        link: '',
        errorType: '',
    } as ModalInfoType,
};

export const modalSlice = createSlice({
    name: 'modal-slice',
    initialState,
    reducers: {
        openModal: (state, { payload }: PayloadAction<ModalInfoType>) => {
            state.isOpenModal = true;
            state.modalInfo = payload;
        },
        closeModal: (state) => {
            state.isOpenModal = false;
            state.modalInfo = initialState.modalInfo;
        },
        setIsOpenModal: (state) => {
            state.isOpenModal = !state.isOpenModal;
        },
    },
    selectors: {
        modalSelector: (state) => state,
        isOpenModalSelector: (state) => state.isOpenModal,
    },
});

export type ModalState = typeof initialState;
export const { openModal, closeModal, setIsOpenModal } = modalSlice.actions;
export const { modalSelector, isOpenModalSelector } = modalSlice.selectors;
export const modalReducer = modalSlice.reducer;

import { isNotEmptyArray } from '../is-not-empty-array';

import { PROVIDER_NAME } from '~/constants/plugin';

export const getAvailableCarriers = async (
    includeUniques: string[] = [],
    excludeMedias: string[] = [],
) => {
    try {
        const { enrolljs } = window;

        //  provName Имя криптопровайдера.
        //  includeUniques Array.<string> Список включаемых уник носителей.
        //  excludeMedias Array.<string> Список исключаемых медиа носителей.
        //  excludeNames Array.<string> Список исключаемых имен носителей
        const carriers = await enrolljs.getAvailableCarriers(
            PROVIDER_NAME,
            includeUniques,
            excludeMedias,
            [],
        );

        return isNotEmptyArray(carriers);
    } catch {
        return false;
    }
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AlertStatuses } from '~/types/common';
import { isNotEmptyArray } from '~/utils/is-not-empty-array';

const initialState = {
    alerts: [] as AlertStatuses[],
};

export const alertSlice = createSlice({
    name: 'alert-slice',
    initialState,
    reducers: {
        setPopupAlert(state, { payload }: PayloadAction<AlertStatuses>) {
            state.alerts.push(payload);
        },
        deleteFirstPopupAlert(state) {
            state.alerts.shift();
        },
    },
    selectors: {
        alertSelector: (state) => state,
        firstAlertSelector: (state) => (isNotEmptyArray(state.alerts) ? state.alerts.at(0) : null),
    },
});

export type AlertState = typeof initialState;
export const { setPopupAlert, deleteFirstPopupAlert } = alertSlice.actions;
export const { alertSelector, firstAlertSelector } = alertSlice.selectors;
export const alertReducer = alertSlice.reducer;

import { RevokeReasonModal } from '~/components/revoke-reason-modal';
import { ViewAnswerModal } from '~/components/view-answer-modal';
import { ModalTextVariant, ModalType } from '~/constants/modal';
import { ModalInfoType } from '~/types/common';
import { Certificate } from '~/utils/transform-certificates-data/type';

type ModalContentType = {
    modalInfo: ModalInfoType;
    currentCertificate: Certificate;
    revokeRequest: () => Promise<void>;
    setCurrentRevokeReason: React.Dispatch<React.SetStateAction<number>>;
};

export const ModalContent = ({
    modalInfo,
    currentCertificate,
    revokeRequest,
    setCurrentRevokeReason,
}: ModalContentType) =>
    modalInfo.type === ModalType.revoke ? (
        <RevokeReasonModal
            revokeHandler={revokeRequest}
            certificateData={currentCertificate}
            setReason={setCurrentRevokeReason}
        />
    ) : (
        <ViewAnswerModal
            isError={modalInfo.isError}
            text={modalInfo.text}
            title={modalInfo.title}
            summaryExpiresOn={currentCertificate.summaryExpiresOn}
            details={
                modalInfo.title === ModalTextVariant.answer ? currentCertificate.details : undefined
            }
            installLink={modalInfo.link}
        />
    );

import { useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';

import { URL_PARAM_LEVEL, ValidationTabItem } from './constants/validation-tab-item';

import styles from './certificate-validation-layout.module.css';

import { ROUTES } from '~/app-router/routes';
import { WithLoader } from '~/hoc/with-loader';
import { usePrivateRoomUrlParams } from '~/hooks/use-private-room-url-params';
import { useGetSvsPolicyQuery } from '~/rtk-queries/endpoints/certificate-verification';
import { useGetFrontConfigQuery } from '~/rtk-queries/endpoints/front-config';

export const CertificateValidationLayout = () => {
    const [selectedKeys, setSelectedKeys] = useState<Array<string>>([]);

    const navigate = useNavigate();

    const { roomUrlParams } = usePrivateRoomUrlParams();
    const { isLoading } = useGetSvsPolicyQuery();
    const { isLoading: isLoadingFrontConfig } = useGetFrontConfigQuery();

    const handleClick = useCallback<(info: { key: string }) => void>(
        ({ key }) => {
            navigate(`${ROUTES.privateRoom}${ROUTES.validation}${key}`);
        },
        [navigate],
    );

    useEffect(() => {
        setSelectedKeys([roomUrlParams[URL_PARAM_LEVEL]]);
    }, [roomUrlParams]);

    return (
        <WithLoader isLoading={isLoading || isLoadingFrontConfig} className={styles.spinner}>
            <Layout className={styles.wrapper}>
                <Sider className={styles.sidebar} width={350}>
                    <Menu
                        onClick={handleClick}
                        className={styles.menu}
                        theme='light'
                        mode='inline'
                        selectedKeys={selectedKeys}
                        items={ValidationTabItem}
                    />
                </Sider>

                <Layout className={styles.layout}>
                    <Content className={styles.content}>
                        <Outlet />
                    </Content>
                </Layout>
            </Layout>
        </WithLoader>
    );
};

export const AlertMessage = {
    ERROR_ESIA:
        'Авторизация через Госуслуги недоступна. Попробуйте еще раз или воспользуйтесь другим способом авторизации.',
    ERROR_LOADING: 'Ошибка связи с сервером. Обновите страницу',
    ERROR_FILE_SIZE: (fileSizeMb: number) =>
        `Ошибка загрузки файла. Размер файла не должен превышать ${fileSizeMb} Мб`,
    ERROR_FILE_EXTENSION: 'Расширение выбранного файла не соответствует разрешённому',
    ERROR_REQUEST_DATA: 'Ошибка запроса данных. Попробуйте еще раз',
};

export const ALERT_LIFETIME = 20 * 1000;

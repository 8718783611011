import React, { useEffect, useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { Empty, Typography } from 'antd';
import classNames from 'classnames';

import { returnColumnsForCertificatesTable } from './constants/return-columns-for-certificates-table';
import { useGetFilters } from './hooks/use-get-filters';

import styles from './certificates-table.module.css';

import { ROUTES } from '~/app-router/routes';
import { ModalContent } from '~/components/modal-content';
import { BUTTON_TEXT } from '~/constants/button';
import { ActionType, PrintTemplateType } from '~/constants/certificates';
import { TABLE_HEIGHT_OFFSET } from '~/constants/certificates-table';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { ModalType } from '~/constants/modal';
import { REVOKE_CERTIFICATE } from '~/constants/revoke-certificate';
import { useAppDispatch, useAppSelector } from '~/hooks';
import { useCertificateAction } from '~/hooks/certificate-actions/use-certificates-actions';
import { startObj, useHookForPagination } from '~/hooks/use-hook-for-pagination';
import { usePrintDocument } from '~/hooks/use-print-document';
import { usePrivateRoomUrlParams } from '~/hooks/use-private-room-url-params';
import { useGetCertificatesListQuery } from '~/rtk-queries/endpoints/certificates-list';
import { CertificatesListResponse } from '~/rtk-queries/types/certificates';
import { CustomButton } from '~/shared/custom-button';
import { CustomModal } from '~/shared/custom-modal';
import { CustomTable } from '~/shared/custom-table/custom-table';
import { modalSelector, setIsOpenModal } from '~/store/slice/modal/modal-slice';
import { TableFilters } from '~/types/common';
import { Certificate } from '~/utils/transform-certificates-data/type';

const { Title } = Typography;

export const CertificatesTable = () => {
    const filtersForCertificates = useGetFilters(LOCAL_STORAGE.filtersForCertificates);
    const { modalInfo, isOpenModal } = useAppSelector(modalSelector);
    const dispatch = useAppDispatch();
    const [currentRevokeReason, setCurrentRevokeReason] = useState(0);
    const [currentCertificate, setCurrentCertificate] = useState<Certificate>({} as Certificate);

    const {
        actionHandler,
        revokeRequest,
        generateKeys,
        isFetchingDetails,
        isRevokeRequest,
        setIsRevokeRequest,
    } = useCertificateAction(currentRevokeReason, currentCertificate);

    const {
        searchParamsObj,
        isEmptyParamObj,
        currentTableSize,
        currentTablePage,
        currentSortOrder,
        currentSortColumn,
    } = useHookForPagination();

    const { onDocumentClick, isFetchingPrintData, documentRef, printButtonHandler } =
        usePrintDocument();
    const { activeRootTab } = usePrivateRoomUrlParams();

    const skip = activeRootTab !== ROUTES.certificateTable;

    const bodyForCertificateList = useMemo(() => {
        const { lastEditDate, ...rest } = filtersForCertificates as TableFilters;
        const isArchive = false;

        const newFilter = {
            ...rest,
            isArchive,
            ...(lastEditDate?.[0] && { lastEditDateFrom: lastEditDate[0] }),
            ...(lastEditDate?.[1] && { lastEditDateTo: lastEditDate[1] }),
        };

        return {
            ...newFilter,
            ...(isEmptyParamObj || isRevokeRequest ? startObj : searchParamsObj),
        };
    }, [filtersForCertificates, isEmptyParamObj, searchParamsObj, isRevokeRequest]);

    const {
        data: certificatesTableData = {} as CertificatesListResponse,
        isFetching,
        refetch,
    } = useGetCertificatesListQuery(skip ? skipToken : bodyForCertificateList);

    const { totalCount, currentPage = [], allTitles = [] } = certificatesTableData;

    const actionVariantHandler = (action: ActionType, data: Certificate) => {
        setCurrentCertificate(data);
        actionHandler[action](data);
    };

    const printHandler = (templateId: PrintTemplateType, data: Certificate) => {
        setCurrentCertificate(data);
        onDocumentClick(templateId, data);
    };

    const toggleModal = () => dispatch(setIsOpenModal());

    const isDataLoading = isFetching || isFetchingDetails || isFetchingPrintData;

    const stylesVariants = {
        [ModalType.print]: styles.printModal,
        [ModalType.generateKeys]: styles.generateKeys,
        [ModalType.revoke]: styles.modalWidth,
        [ModalType.viewAnswer]: styles.modalWidth,
    };

    const footerContent = {
        [ModalType.generateKeys]: (
            <CustomButton key={BUTTON_TEXT.continue} onClick={generateKeys}>
                {BUTTON_TEXT.continue}
            </CustomButton>
        ),
        [ModalType.print]: (
            <React.Fragment>
                <CustomButton key={BUTTON_TEXT.cancel} onClick={toggleModal}>
                    {BUTTON_TEXT.cancel}
                </CustomButton>
                <CustomButton
                    key={BUTTON_TEXT.print}
                    onClick={printButtonHandler}
                    className='enterPageButtonYes'
                >
                    {BUTTON_TEXT.print}
                </CustomButton>
            </React.Fragment>
        ),
        [ModalType.revoke]: null,
        [ModalType.viewAnswer]: null,
    };

    const modalWidth = modalInfo.type === ModalType.print ? '90%' : 'fit-content';

    const columns = returnColumnsForCertificatesTable({
        printHandler,
        actionVariantHandler,
        allTitles,
    });

    useEffect(() => {
        if (isRevokeRequest) {
            refetch();
        }
        setIsRevokeRequest(false);
    }, [isRevokeRequest, refetch, setIsRevokeRequest]);

    return (
        <React.Fragment>
            <CustomTable
                className={classNames(styles.certificatesTable, {
                    [styles.emptyTable]: !currentPage.length,
                })}
                dataSource={currentPage}
                columns={columns}
                currentTablePage={currentTablePage}
                currentTableSize={currentTableSize}
                currentSortColumn={currentSortColumn}
                currentSortOrder={currentSortOrder}
                totalCount={totalCount}
                loading={{ spinning: isDataLoading }}
                filterName={LOCAL_STORAGE.filtersForCertificates}
                scrollX={1500}
                scrollY={`calc(100vh - ${TABLE_HEIGHT_OFFSET}px)`}
                rowKey={({ key }) => key}
                locale={{
                    emptyText: isDataLoading ? (
                        <span style={{ display: 'inline-block', height: 100 }} />
                    ) : (
                        <Empty description='Нет данных' />
                    ),
                }}
            />
            <CustomModal
                open={isOpenModal}
                className={classNames(styles.modal, stylesVariants[modalInfo.type])}
                setOpenModal={toggleModal}
                isClose={isOpenModal}
                width={modalWidth}
                title={
                    modalInfo.type === ModalType.revoke ? (
                        <Title level={4} style={{ marginBottom: 0 }}>
                            {REVOKE_CERTIFICATE.revokeModal.title}
                        </Title>
                    ) : null
                }
                footer={footerContent[modalInfo.type]}
            >
                {modalInfo.type === ModalType.print ? (
                    <iframe className={styles.iframe} ref={documentRef} title={modalInfo.title} />
                ) : (
                    <ModalContent
                        modalInfo={modalInfo}
                        currentCertificate={currentCertificate}
                        revokeRequest={revokeRequest}
                        setCurrentRevokeReason={setCurrentRevokeReason}
                    />
                )}
            </CustomModal>
        </React.Fragment>
    );
};

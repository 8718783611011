import { FC } from 'react';
import { Card, Table } from 'antd';

import { MainInfoPropsType, returnArrayForTable } from './constants/return-array-for-table';
import { returnColumns } from './constants/return-columns';

export const MainInfo: FC<MainInfoPropsType> = ({ ...restProps }) => (
    <Card>
        <Table
            dataSource={returnArrayForTable(restProps).filter((el) => el.columnResult)}
            columns={returnColumns(restProps.result)}
            pagination={false}
            showHeader={false}
            scroll={{ x: 200 }}
        />
    </Card>
);

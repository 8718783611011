import { FC, MouseEventHandler } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';

import { ButtonsBlock } from '../buttons-block';

import styles from './certificates-block.module.css';

import { CertificatesCards } from '~/components/certificates-cards';
import { stepName } from '~/constants/step-name';
import { WithLoader } from '~/hoc/with-loader';
import { useGetCertificatesValues } from '~/hooks/use-get-certificates-values';
import { AnimatedEllipsis } from '~/shared/animated-ellipsis';
import { EnterOutletContextType } from '~/shared/types';
import { StepParams } from '~/types/router';

type CertificatesBlockProps = {
    onClickButtonBottom: MouseEventHandler<HTMLElement>;
    isLoadingPlugin: boolean;
};

const LOADING_CERTIFICATES_TEXT = 'Сертификаты загружаются';
const AUTH_PROCESSING_TEXT = 'Пожалуйста, подождите';

export const CertificatesBlock: FC<CertificatesBlockProps> = ({
    onClickButtonBottom,
    isLoadingPlugin,
}) => {
    const { stepName: currentStep } = useParams<StepParams>();
    const { getAllCertificate, userCertificates, isFetchingCertificates, isAccessDenied } =
        useGetCertificatesValues();

    const { authorizationByCertificateHandler, isLoadingInAuthByCert, isDisabledCards } =
        useOutletContext<EnterOutletContextType>();

    const isFinalScreen = currentStep === stepName.five;
    const isLoading = isLoadingInAuthByCert || isFetchingCertificates;
    const tip = (
        <AnimatedEllipsis
            title={isFetchingCertificates ? LOADING_CERTIFICATES_TEXT : AUTH_PROCESSING_TEXT}
        />
    );
    const loaderClassName = isFetchingCertificates
        ? styles.loadCertificates
        : styles.authProcessing;

    return (
        <div className={styles.wrapper}>
            {isFinalScreen && (
                <WithLoader
                    style={{ height: '100%' }}
                    isLoading={isLoading}
                    tip={tip}
                    className={loaderClassName}
                >
                    <CertificatesCards
                        userCertificates={userCertificates}
                        authorizationByCertificateHandler={authorizationByCertificateHandler}
                        isDisabled={isDisabledCards}
                        isAccessDenied={isAccessDenied}
                    />
                </WithLoader>
            )}
            <ButtonsBlock
                onClickButtonBottom={onClickButtonBottom}
                updateCertificates={getAllCertificate}
                isLoadingInAuthByCert={isLoadingInAuthByCert}
                isFetchingCertificates={isFetchingCertificates}
                isLoadingPlugin={isLoadingPlugin}
                isAccessDenied={isAccessDenied}
            />
        </div>
    );
};

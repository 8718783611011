import { useState } from 'react';

import { usePluginChecker } from './use-plugin-checker';

import { ERROR_TEXTS, ModalType } from '~/constants/modal';
import { PluginError } from '~/constants/plugin';
import { useAppDispatch } from '~/hooks';
import { useRevokeCertificateMutation } from '~/rtk-queries/endpoints/certificates-list';
import { setPopupAlert } from '~/store/slice/alert/alert-slice';
import { closeModal, openModal } from '~/store/slice/modal/modal-slice';
import { createAlertError } from '~/utils/create-alert-error';
import { isCertificateInstance } from '~/utils/is-certificate-variant';
import { revokeCertificateUtils } from '~/utils/plugin/revoke-certificate-util';
import { Certificate } from '~/utils/transform-certificates-data/type';

type useRevokeCertificateType = {
    reason: number;
    currentCertificate: Certificate;
};

export const useRevokeCertificate = ({ reason, currentCertificate }: useRevokeCertificateType) => {
    const dispatch = useAppDispatch();
    const [isRevokeRequest, setIsRevokeRequest] = useState(false);
    const { checkExtension } = usePluginChecker();

    const [
        revokeCertificate,
        {
            isSuccess: isSuccessRevokeCertificate,
            isLoading: isLoadingRevoke,
            reset: resetRevokeCertificate,
        },
    ] = useRevokeCertificateMutation();

    const openRevokeModal = async () => {
        const isExtensionRun = await checkExtension();

        if (!isExtensionRun) return;
        dispatch(
            openModal({
                text: '',
                isError: false,
                type: ModalType.revoke,
                title: '',
            }),
        );
    };

    const handleRevokeError = (rawRequest: { title: string; text: string; isError: boolean }) => {
        if (rawRequest.title === ERROR_TEXTS[PluginError.cancel]) {
            dispatch(setPopupAlert(createAlertError(rawRequest.text).alert));
        } else {
            dispatch(
                openModal({
                    text: rawRequest.text,
                    isError: rawRequest.isError,
                    type: ModalType.viewAnswer,
                    title: rawRequest.title,
                }),
            );
        }
    };

    const revokeRequest = async () => {
        dispatch(closeModal());

        try {
            if (!isCertificateInstance(currentCertificate)) return;

            const rawRequest = await revokeCertificateUtils(currentCertificate.thumbprint, reason);

            if (rawRequest.isError) {
                handleRevokeError(rawRequest);

                return;
            }

            await revokeCertificate({ rawRequest: rawRequest.text });
            setIsRevokeRequest(true);
        } catch (e) {
            console.error(e);
        }
    };

    return {
        openRevokeModal,
        revokeRequest,
        isRevokeRequest,
        setIsRevokeRequest,
        isSuccessRevokeCertificate,
        isLoadingRevoke,
        resetRevokeCertificate,
    };
};

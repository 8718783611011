import { useEffect, useRef, useState } from 'react';
import { createArrayLoader, createEnvironment } from 'twing';

import { PrintTemplateType, statusesRevokeInProgress } from '~/constants/certificates';
import { LifeStatus } from '~/constants/certificates-table';
import { ModalTextVariant, ModalType } from '~/constants/modal';
import { REVOKE_REASON_PRINT, RevReasonPKI } from '~/constants/revoke-certificate';
import { useAppDispatch, useAppSelector } from '~/hooks';
import { useLazyGetNamePolicyQuery } from '~/rtk-queries/endpoints/name-policy';
import {
    useLazyGetPKICertificateQuery,
    useLazyGetPKICertRequestQuery,
    useLazyGetPKIRevokeQuery,
    useLazyGetPKIUserQuery,
} from '~/rtk-queries/endpoints/pki';
import {
    useLazyGetInitForPrintQuery,
    useLazyGetPrintedTemplateQuery,
} from '~/rtk-queries/endpoints/template';
import { Context } from '~/rtk-queries/types/pki';
import { modalSelector, openModal } from '~/store/slice/modal/modal-slice';
import {
    isCertificateApplication,
    isCertificateInstance,
    isCertificatePreApplication,
    isCertificateRevocation,
} from '~/utils/is-certificate-variant';
import { Certificate } from '~/utils/transform-certificates-data/type';

export const usePrintDocument = () => {
    const documentRef = useRef<HTMLIFrameElement | null>(null);
    const dispatch = useAppDispatch();
    const { isOpenModal } = useAppSelector(modalSelector);
    const [originalTitle, setOriginalTitle] = useState(document.title);
    const [content, setContent] = useState('');

    const [getPrintedTemplate, { isFetching: isFetchingTemplate }] =
        useLazyGetPrintedTemplateQuery();
    const [getInitForPrint, { isFetching: isFetchingInit }] = useLazyGetInitForPrintQuery();
    const [getPKICertRequest, { isFetching: isFetchingRequest }] = useLazyGetPKICertRequestQuery();
    const [getPKICertificate, { isFetching: isFetchingCertificate }] =
        useLazyGetPKICertificateQuery();
    const [getPKIUser, { isFetching: isFetchingUser }] = useLazyGetPKIUserQuery();
    const [getNameAttributes, { isFetching: isFetchingOidNames }] = useLazyGetNamePolicyQuery();
    const [getRevokedCertificateData, { isFetching: isFetchingRevokedCertificateData }] =
        useLazyGetPKIRevokeQuery();

    const isFetchingPrintData =
        isFetchingInit ||
        isFetchingOidNames ||
        isFetchingRequest ||
        isFetchingTemplate ||
        isFetchingUser ||
        isFetchingCertificate ||
        isFetchingRevokedCertificateData;

    const getRevocationReason = (revocationReason?: RevReasonPKI) =>
        REVOKE_REASON_PRINT[revocationReason as RevReasonPKI] ??
        REVOKE_REASON_PRINT[RevReasonPKI.Unspecified];

    const fetchUser = async (userId?: string) => {
        if (!userId) return null;
        const data = await getPKIUser(userId).unwrap();

        return data ? { user: data?.pkiUser, folderName: data?.pkiUser.folder ?? '' } : null;
    };

    const fetchCertificateData = async (certificateId?: string) => {
        if (!certificateId) return null;
        const data = await getPKICertificate(certificateId).unwrap();

        return data ? { certificate: data.pkiCertificate } : null;
    };

    const fetchCertificateRequest = async (certRequestId?: string) => {
        if (!certRequestId) return null;
        const data = await getPKICertRequest(certRequestId).unwrap();

        return data
            ? { certRequest: data.pkiCertRequest, userId: data.pkiCertRequest.userId }
            : null;
    };

    const handlePreApplicationStatus = async (certificate: Certificate): Promise<Context> => {
        if (!isCertificatePreApplication(certificate)) return {};
        const userData = await fetchUser(certificate.pkiUserId);

        return userData || {};
    };

    const handleApplicationStatus = async (certificate: Certificate): Promise<Context> => {
        if (!isCertificateApplication(certificate)) return {};
        const certRequestData = await fetchCertificateRequest(certificate.certRequestId);

        if (!certRequestData) return {};
        const { userId, certRequest } = certRequestData;
        const userData = await fetchUser(userId);

        return { ...userData, certRequest };
    };

    const handleCertificateStatus = async (certificate: Certificate): Promise<Context> => {
        if (!isCertificateInstance(certificate)) return {};
        const certificateData = await fetchCertificateData(certificate.certificateId);

        if (!certificateData) return {};

        const { userId, revocationReason } = certificateData.certificate;
        const userData = await fetchUser(userId);

        return {
            ...userData,
            ...certificateData,
            revReason: getRevocationReason(revocationReason as RevReasonPKI),
        };
    };

    const handleCertificateStatusRevocation = async (
        certificate: Certificate,
    ): Promise<Context> => {
        if (!isCertificateRevocation(certificate)) return {};

        const { status, revokeRequestId } = certificate;

        if (!status || !statusesRevokeInProgress.includes(status.statusCode) || !revokeRequestId)
            return {};

        const revocationData = await getRevokedCertificateData(revokeRequestId).unwrap();

        if (!revocationData?.certificateId) return {};

        const { userId, certificateId, revocationReason } = revocationData;
        const userData = await fetchUser(userId);
        const certificateData = await fetchCertificateData(certificateId);

        return {
            ...userData,
            ...certificateData,
            revReason: getRevocationReason(revocationReason as RevReasonPKI),
        };
    };

    const lifeStatusActions: Record<LifeStatus, (certificate: Certificate) => Promise<Context>> = {
        [LifeStatus.preApplication]: handlePreApplicationStatus,
        [LifeStatus.application]: handleApplicationStatus,
        [LifeStatus.certificate]: handleCertificateStatus,
        [LifeStatus.revocation]: handleCertificateStatusRevocation,
    };

    const createContext = async (certificate: Certificate) =>
        lifeStatusActions[certificate.lifeStatus](certificate);

    const printButtonHandler = () => {
        if (documentRef.current) {
            documentRef.current.contentWindow?.print();
        }
    };

    const onDocumentClick = async (templateId: PrintTemplateType, certificate: Certificate) => {
        try {
            const [template, header, initForPrint] = await Promise.all([
                getPrintedTemplate(templateId).unwrap(),
                getPrintedTemplate(PrintTemplateType.Header).unwrap(),
                getInitForPrint().unwrap(),
            ]);

            const initialContext: Context = {
                dictionary: initForPrint?.dictionary,
                timeZone: initForPrint?.config.TIME_ZONE,
            };

            const context = await createContext(certificate);
            const nameAttributes = await getNameAttributes({
                folderName: context.folderName || '',
            }).unwrap();

            const loader = createArrayLoader({
                'index.twig': template as string,
                tplHeader: header as string,
            });
            const environment = createEnvironment(loader);

            const output = await environment.render('index.twig', {
                ...context,
                ...initialContext,
                oidNames: nameAttributes,
            });

            setContent(output);
            setOriginalTitle(document.title);
            const parser = new DOMParser();
            const doc = parser.parseFromString(output, 'text/html');
            const title = doc.title || document.title;

            document.title = title;

            dispatch(
                openModal({
                    isError: false,
                    type: ModalType.print,
                    title,
                }),
            );

            setTimeout(printButtonHandler, 500);
        } catch (_) {
            dispatch(
                openModal({
                    isError: true,
                    type: ModalType.viewAnswer,
                    text: ModalTextVariant.printError,
                    title: '',
                }),
            );
        }
    };

    useEffect(() => {
        if (!isOpenModal) {
            document.title = originalTitle;
            setContent('');
        }
    }, [isOpenModal, originalTitle]);

    useEffect(() => {
        if (documentRef.current) {
            const iframe = documentRef.current;
            const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;

            if (iframeDocument) {
                iframeDocument.open();
                iframeDocument.write(content);
                iframeDocument.close();
            }
        }
    }, [isOpenModal, content]);

    return {
        onDocumentClick,
        isFetchingPrintData,
        documentRef,
        printButtonHandler,
    };
};

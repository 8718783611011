import { Outlet } from 'react-router-dom';
import ErrorBoundary from 'antd/es/alert/ErrorBoundary';

import { CustomAlert } from '~/components/custom-alert';

export const AppLayout = () => (
    <ErrorBoundary>
        <Outlet />
        <CustomAlert />
    </ErrorBoundary>
);

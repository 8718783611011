enum RevokeStatusCode {
    CauseUnknown = 0,
    KeyCompromise = 1,
    CompromiseCertificationCenter = 2,
    InformationChanged = 3,
    CertificateReplacement = 4,
    CertificateNoNeeded = 5,
}

enum RevokeReasonType {
    CauseUnknown = 'Причина не указана',
    KeyCompromise = 'Компрометация ключа',
    CompromiseCertificationCenter = 'Компрометация ключа ЦС',
    InformationChanged = 'Изменение принадлежности',
    CertificateReplacement = 'Замена сертификата',
    CertificateNoNeeded = 'Прекращение деятельности',
}

export enum RevReasonPKI {
    Unspecified = 0,
    AffiliationChanged = 1,
    CACompromise = 2,
    KeyCompromise = 3,
    CessationOfOperation = 4,
    Superseded = 5,
}

// Возможно в дальнейшем причины отзыва в печатной форме будут соответствовать документации PKI,
// но в данный момент решено, что они соответствуют текстам из модального окна на отзыв (в рамках задачи CRYP-469)

// export enum RevReasonPKIText {
//     Unspecified = 'Не указана',
//     AffiliationChanged = 'Изменение принадлежности',
//     CACompromise = 'Компрометация ЦС',
//     KeyCompromise = 'Компрометация ключа',
//     CessationOfOperation = 'Прекращение работы',
//     Superseded = 'Замена сертификата',
// }

export const REVOKE_REASON_PRINT = {
    [RevReasonPKI.Unspecified]: RevokeReasonType.CauseUnknown, // RevReasonPKIText.Unspecified,
    [RevReasonPKI.AffiliationChanged]: RevokeReasonType.InformationChanged, // RevReasonPKIText.AffiliationChanged,
    [RevReasonPKI.CACompromise]: RevokeReasonType.CompromiseCertificationCenter, // RevReasonPKIText.CACompromise,
    [RevReasonPKI.CessationOfOperation]: RevokeReasonType.CertificateNoNeeded, //  RevReasonPKIText.CessationOfOperation,
    [RevReasonPKI.KeyCompromise]: RevokeReasonType.KeyCompromise, // RevReasonPKIText.KeyCompromise,
    [RevReasonPKI.Superseded]: RevokeReasonType.CertificateReplacement, // RevReasonPKIText.Superseded,
};

export const REVOKE_REASON = [
    {
        value: RevokeStatusCode.CauseUnknown,
        label: RevokeReasonType.CauseUnknown,
    },
    {
        value: RevokeStatusCode.KeyCompromise,
        label: RevokeReasonType.KeyCompromise,
    },
    {
        value: RevokeStatusCode.CompromiseCertificationCenter,
        label: RevokeReasonType.CompromiseCertificationCenter,
    },
    {
        value: RevokeStatusCode.InformationChanged,
        label: RevokeReasonType.InformationChanged,
    },
    {
        value: RevokeStatusCode.CertificateReplacement,
        label: RevokeReasonType.CertificateReplacement,
    },
    {
        value: RevokeStatusCode.CertificateNoNeeded,
        label: RevokeReasonType.CertificateNoNeeded,
    },
];
export const REVOKE_CERTIFICATE = {
    revokeModal: {
        title: 'Подключите носитель с сертификатом',
        buttonText: 'Отозвать сертификат',
        label: 'Выберите причину отзыва сертификата',
        name: 'revoke',
    },
};

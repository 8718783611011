import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { CommonResponse } from '~/types/common';

export const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError =>
    typeof error === 'object' && error !== null && 'status' in error;

export const isErrorWithDataMessage = (
    error: unknown,
): error is FetchBaseQueryError & { data: CommonResponse } =>
    isFetchBaseQueryError(error) &&
    typeof error.data === 'object' &&
    error.data !== null &&
    'message' in error.data;

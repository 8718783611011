import React from 'react';
import { Card, Table } from 'antd';

import { AdditionalResultPropsType, returnArrayForTable } from './constants/return-array-for-table';
import { returnColumns } from './constants/return-columns';

export const AdditionalResult: React.FC<AdditionalResultPropsType> = ({ errors, result }) => (
    <Card>
        <Table
            dataSource={returnArrayForTable({ result, errors })}
            columns={returnColumns(result)}
            pagination={false}
            showHeader={false}
            scroll={{ x: 400 }}
        />
    </Card>
);

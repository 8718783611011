import { ColumnsType } from 'antd/es/table';
import classNames from 'classnames';

import { TableResult, TableResultColumn } from '../../../../types/types';

import { COLUMN_NAME } from './return-array-for-table';

import styles from '~/shared/result-block-with-collapse/result-block-with-collapse.module.css';

export const returnColumns = (result: boolean): ColumnsType<TableResultColumn> => [
    {
        key: TableResult.columnName,
        title: TableResult.columnName,
        dataIndex: TableResult.columnName,
        width: '50%',
    },
    {
        key: TableResult.columnResult,
        title: TableResult.columnResult,
        dataIndex: TableResult.columnResult,
        width: '50%',
        render: (value, record, index) => {
            const condition =
                record[TableResult.columnName] === COLUMN_NAME.resultOfChecking ||
                record[TableResult.columnName] === COLUMN_NAME.additionalInformation;

            return (
                <span
                    key={`${value}-${index}`}
                    className={classNames({
                        [styles.textSuccessColor]: condition && result,
                        [styles.textErrorColor]: condition && !result,
                    })}
                >
                    {value}
                </span>
            );
        },
    },
];

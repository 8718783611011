import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import { RequestQueue } from './request-queue';

import { HttpStatus } from '~/constants/http-status';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { API } from '~/rtk-queries/constants/api';
import { resetAuth } from '~/store/slice/auth/auth-slice';
import { getLocalStorageItem } from '~/utils/local-storage';

const isProduction = process.env.NODE_ENV === 'production';

const requestQueue = new RequestQueue();

const baseQuery = fetchBaseQuery({
    credentials: 'include',
});

export const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const response = await baseQuery(args, api, extraOptions);

    // логика протухания токена
    if (response.error && response.error.status === HttpStatus.UNAUTHORIZED) {
        if (requestQueue.shouldSubscribe) {
            return new Promise((res, rej) => {
                requestQueue.subscribe(async (abort = false) => {
                    if (abort) {
                        rej(response.error);

                        return;
                    }

                    const queuedResponse = await baseQuery(args, api, extraOptions);

                    if (queuedResponse.error) {
                        rej(queuedResponse.error);
                    } else {
                        res(queuedResponse);
                    }
                });
            });
        }

        requestQueue.shouldSubscribe = true;

        const sessionId = getLocalStorageItem(LOCAL_STORAGE.sessionId);

        if (!sessionId) {
            api.dispatch(resetAuth());
            requestQueue.notify(true);

            return response;
        }

        const refreshResponse = await baseQuery(
            {
                url: API.refreshToken,
                method: 'POST',
                body: {
                    sessionId: Number(sessionId),
                },
            },
            api,
            extraOptions,
        );

        if (refreshResponse.error) {
            api.dispatch(resetAuth());
            requestQueue.notify(true);

            return response;
        }

        requestQueue.notify();

        return baseQuery(args, api, extraOptions);
    }

    return response;
};

export const api = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({}),
    refetchOnFocus: false,
    refetchOnReconnect: isProduction,
});

import { FC, useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import { FormItemProps } from 'antd/es/form';

import styles from './custom-input.module.css';

import { useCustomProps } from '~/hooks/use-custom-props';
import { CustomTooltip } from '~/shared/custom-tooltip';
import { isNotEmptyArray } from '~/utils/is-not-empty-array';

type CustomInputProps = Partial<{
    value: string;
    disabled: boolean;
    minLength: number;
    maxLength: number;
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}> &
    FormItemProps;

export const CustomInput: FC<CustomInputProps> = ({
    value,
    name,
    label,
    disabled,
    minLength,
    maxLength,
    rules,
    onBlur,
    ...restProps
}) => {
    const [required, setRequired] = useState(false);
    const { titleForTooltip, placeholder, backgroundColorDisabled } = useCustomProps(
        value,
        disabled || false,
        label,
    );

    const onFocus = (event: { target: { setAttribute: (arg0: string, arg1: string) => void } }) => {
        event.target.setAttribute('autocomplete', 'off');
    };

    const lengthSetup = minLength === maxLength && maxLength === 0 ? {} : { minLength, maxLength };

    useEffect(() => {
        if (isNotEmptyArray(rules)) {
            if (Object.keys(rules[0]).includes('required')) {
                setRequired(true);
            }
        }
    }, [rules]);

    return (
        <CustomTooltip title={titleForTooltip}>
            <Form.Item className={styles.inputItem} {...restProps} {...{ name, label, rules }}>
                <Input
                    className={styles.input}
                    style={backgroundColorDisabled}
                    {...lengthSetup}
                    {...{ placeholder, disabled, required, onFocus, onBlur }}
                />
            </Form.Item>
        </CustomTooltip>
    );
};

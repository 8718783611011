import { useLazyGetSignOptionsQuery } from '~/rtk-queries/endpoints/front-config';
import { getAvailableCarriers } from '~/utils/plugin/get-available-carriers';

export const useCheckProtectedMedia = () => {
    const [getSignOptions] = useLazyGetSignOptionsQuery();

    const checkProtectedMediaCondition = async () => {
        const { data: signData } = await getSignOptions();
        const existsProtected = await getAvailableCarriers(
            signData?.includeUniques,
            signData?.excludeMedias,
        );

        return { existsProtected, includeUniques: signData?.includeUniques };
    };

    return { checkProtectedMediaCondition };
};

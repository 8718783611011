import { PROVIDER_NAME } from '~/constants/plugin';

// проверка, установлен ли Crypto CSP
export const checkCryptoInstalled = async (
    setCryptoInstalled?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    try {
        const { cadesplugin } = window;

        if (!cadesplugin) return false;
        const ProviderType = 80;

        const oAbout = await cadesplugin.CreateObjectAsync('CAdESCOM.About');
        const oVersion = await oAbout.CSPVersion(
            PROVIDER_NAME,
            parseInt(ProviderType.toString(), 10),
        );

        const Version = oVersion.toString();

        if (setCryptoInstalled) setCryptoInstalled(true);

        return Version;
    } catch (_) {
        if (setCryptoInstalled) setCryptoInstalled(false);

        return false;
    }
};

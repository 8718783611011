import { Link } from 'react-router-dom';

import styles from './not-found.module.css';

import { ROUTES } from '~/app-router/routes';
import { notFoundPageText } from '~/constants/not-found-page';
import { LogoBlock } from '~/shared/logo';

export const NotFound = () => (
    <div className={styles.wrapper}>
        <div className={styles.logoBlock}>
            <LogoBlock type='white' />
        </div>
        <div className={styles.card}>
            <p className={styles.note}>{notFoundPageText.header}</p>
            <h2 className={styles.title}>{notFoundPageText.title}</h2>
            <p className={styles.text}>{notFoundPageText.description}</p>
            <p className={styles.text}>
                {notFoundPageText.additionalText}
                <Link to={ROUTES.startPage} replace={true} className={styles.link}>
                    {notFoundPageText.linkText}
                </Link>
            </p>
        </div>
    </div>
);

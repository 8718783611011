import { FC, useCallback } from 'react';
import { Flex, notification, Typography } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';

import styles from './index.module.css';

import { LOCAL_STORAGE } from '~/constants/local-storage';
import { CustomButton } from '~/shared/custom-button';
import { getLocalStorageItem, setLocalStorageItem } from '~/utils/local-storage';

const COOKIES_NOTIFICATION_KEY = LOCAL_STORAGE.cookieNoticeAccepted;

const Message: FC<{ api: NotificationInstance }> = ({ api }) => {
    const acceptCookies = () => {
        setLocalStorageItem(LOCAL_STORAGE.cookieNoticeAccepted, 'true');
        api.destroy(COOKIES_NOTIFICATION_KEY);
    };

    return (
        <Flex gap={10} align='center'>
            <Typography.Text>
                Мы используем&nbsp;
                <Typography.Link
                    className={styles.cookiesLink}
                    href='https://policies.google.com/technologies/partner-sites?hl=ru'
                >
                    файлы cookie
                </Typography.Link>
                , чтобы сайт был лучше для вас.
            </Typography.Text>
            <CustomButton presentation='secondary' onClick={acceptCookies}>
                ОК
            </CustomButton>
        </Flex>
    );
};

export const useCookiesNotification = () => {
    const [api, context] = notification.useNotification();

    const noticeCookies = useCallback(() => {
        const cookieNoticeAccepted = getLocalStorageItem(LOCAL_STORAGE.cookieNoticeAccepted);

        if (!cookieNoticeAccepted) {
            api.open({
                key: COOKIES_NOTIFICATION_KEY,
                duration: null,
                placement: 'bottom',
                icon: null,
                closable: false,
                message: <Message {...{ api }} />,
                className: styles.cookiesNotification,
                style: {
                    width: 'max-content',
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { noticeCookies, context };
};

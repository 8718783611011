import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useAppSelector } from '~/hooks';
import { isAuthenticatedSelector } from '~/store/slice/auth/auth-slice';

type AuthGuardProps = PropsWithChildren & {
    redirectUrl: string;
    isProtected?: boolean;
};

export const AuthGuard: FC<AuthGuardProps> = ({ children, redirectUrl, isProtected = false }) => {
    const isUserAuth = useAppSelector(isAuthenticatedSelector);

    // redirect неавторизованного
    if (isProtected && !isUserAuth) {
        return <Navigate to={redirectUrl} replace={true} />;
    }

    // redirect авторизованного
    if (!isProtected && isUserAuth) {
        return <Navigate to={redirectUrl} replace={true} />;
    }

    return children;
};

import React, { useState } from 'react';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';

type FormTooltipProps = {
    title: string;
    children: JSX.Element;
    placement?: TooltipPlacement;
    onClick?: () => void;
};

export const FormTooltip = ({ title, placement, children }: FormTooltipProps) => {
    const [visible, setVisible] = useState(false);

    return (
        <Tooltip
            open={visible}
            onOpenChange={setVisible}
            fresh={true}
            placement={placement}
            title={title}
            styles={{ root: { maxWidth: 'var(--width-for-column-200)' } }}
        >
            {React.cloneElement(children, {
                onMouseEnter: () => setVisible(true),
                onClick: () => {
                    setVisible(false);
                    children.props?.onClick?.();
                },
            })}
        </Tooltip>
    );
};

export type AdditionalResultPropsType = {
    errors: Array<string>;
    result: boolean;
};

export const COLUMN_NAME = {
    resultOfChecking: 'Результат проверки',
    errors: 'Ошибки',
};

export const returnArrayForTable = ({ result, errors }: AdditionalResultPropsType) => {
    const firstElOfResultArray = {
        columnName: COLUMN_NAME.resultOfChecking,
        columnResult: result ? 'Сертификат прошел проверку' : 'Сертификат не прошел проверку',
    };

    return result
        ? [firstElOfResultArray]
        : [firstElOfResultArray, { columnName: COLUMN_NAME.errors, columnResult: errors }];
};

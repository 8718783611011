import { useCallback } from 'react';

import { LifeStatus } from '~/constants/certificates-table';
import {
    useLazyGetCertificatePKIDetailsQuery,
    useSendToArchiveMutation,
} from '~/rtk-queries/endpoints/certificates-list';
import { downloadCertificate } from '~/utils/download-certificate';
import { getRequestData } from '~/utils/get-request-data';
import { isCertificateInstance } from '~/utils/is-certificate-variant';
import { titleCertificate } from '~/utils/title-certificate';
import { Certificate } from '~/utils/transform-certificates-data/type';

export const useArchiveAndSaveActions = () => {
    const [
        sendToArchiveRequest,
        {
            isSuccess: isSuccessSendToArchive,
            isLoading: isLoadingSendToArchive,
            reset: resetSendToArchive,
        },
    ] = useSendToArchiveMutation();

    const [getPKIDetails, { isFetching: isFetchingPKIDetailsInSave }] =
        useLazyGetCertificatePKIDetailsQuery();

    const sendToArchive = useCallback(
        (data: Certificate) => {
            const requestData = getRequestData(data);

            sendToArchiveRequest(requestData);
        },
        [sendToArchiveRequest],
    );

    const saveToFile = useCallback(
        async (data: Certificate) => {
            if (data.lifeStatus !== LifeStatus.certificate) return;
            if (isCertificateInstance(data)) {
                const { data: pkiData, isSuccess } = await getPKIDetails({
                    certificateId: data?.certificateId,
                });

                if (isSuccess) {
                    downloadCertificate({
                        fileName: titleCertificate(data.title),
                        base64File: pkiData.certificateDetails.rawCertificate,
                    });
                }
            }
        },
        [getPKIDetails],
    );

    return {
        sendToArchive,
        saveToFile,
        isFetchingPKIDetailsInSave,
        isSuccessSendToArchive,
        isLoadingSendToArchive,
        resetSendToArchive,
    };
};

import React from 'react';
import { Select, Space } from 'antd';

import styles from './revoke-reason-modal.module.css';

import { REVOKE_CERTIFICATE, REVOKE_REASON } from '~/constants/revoke-certificate';
import { CertificatesTitle } from '~/shared/certificates-title';
import { CustomButton } from '~/shared/custom-button';
import { Certificate } from '~/utils/transform-certificates-data/type';

type RevokeReasonModalType = {
    certificateData: Certificate;
    setReason: React.Dispatch<React.SetStateAction<number>>;
    revokeHandler: () => void;
};

export const RevokeReasonModal = ({
    certificateData,
    setReason,
    revokeHandler,
}: RevokeReasonModalType) => (
    <React.Fragment>
        <div className={styles.space}>
            <CertificatesTitle
                title={certificateData.title}
                align='start'
                needColorSelection={true}
            />
            <Space direction='vertical' className={styles.selectWrapper}>
                <label htmlFor={REVOKE_CERTIFICATE.revokeModal.name}>
                    {REVOKE_CERTIFICATE.revokeModal.label}
                </label>
                <Select
                    id={REVOKE_CERTIFICATE.revokeModal.name}
                    className={styles.select}
                    defaultValue={0}
                    onChange={setReason}
                    options={REVOKE_REASON}
                />
            </Space>
        </div>

        <Space className={styles.spaceButton} direction='vertical' align='end'>
            <CustomButton className={styles.revokeButton} onClick={revokeHandler}>
                {REVOKE_CERTIFICATE.revokeModal.buttonText}
            </CustomButton>
        </Space>
    </React.Fragment>
);

import { Navigate, RouteObject } from 'react-router-dom';

import { AuthGuard } from '../guard/require-auth-guard';

import { ApplicationFormGuard } from './guard/application-form-guard';

import { ROUTES } from '~/app-router/routes';
import { Archive } from '~/components/archive';
import { CertificatesTable } from '~/components/certificates-table';
import {
    CheckCertificate,
    CheckDocument,
    SignDocument,
} from '~/components/check-and-sign-certificate';
import { CertificateValidationLayout } from '~/layouts/certificate-validation-layout';
import { PrivateRoomLayout } from '~/layouts/private-room-layout';
import { ApplicationTab } from '~/pages/application-tab';
import { CertificateTemplate } from '~/pages/certificate-template';
import { MchdTab } from '~/pages/mchd-tab';

const trimSlash = (path: string) => path.slice(1);

export const privateRoomRoute: RouteObject = {
    path: ROUTES.privateRoom,
    element: (
        <AuthGuard redirectUrl={ROUTES.startPage} isProtected={true}>
            <PrivateRoomLayout />
        </AuthGuard>
    ),
    children: [
        {
            index: true,
            element: (
                <Navigate to={`${ROUTES.privateRoom}${ROUTES.certificateTable}`} replace={true} />
            ),
        },
        {
            path: trimSlash(ROUTES.certificateTemplate),
            children: [
                {
                    index: true,
                    element: <CertificateTemplate />,
                },
                {
                    path: trimSlash(ROUTES.applicationForm),
                    element: (
                        <ApplicationFormGuard>
                            <ApplicationTab />
                        </ApplicationFormGuard>
                    ),
                },
            ],
        },
        {
            path: trimSlash(ROUTES.certificateTable),
            element: <CertificatesTable />,
        },
        {
            path: trimSlash(ROUTES.validation),
            element: <CertificateValidationLayout />,
            children: [
                {
                    index: true,
                    element: (
                        <Navigate
                            to={`${ROUTES.privateRoom}${ROUTES.validation}${ROUTES.checkCertificate}`}
                            replace={true}
                        />
                    ),
                },
                {
                    path: trimSlash(ROUTES.checkCertificate),
                    element: <CheckCertificate />,
                },
                {
                    path: trimSlash(ROUTES.checkDocument),
                    element: <CheckDocument />,
                },
                {
                    path: trimSlash(ROUTES.signDocument),
                    element: <SignDocument />,
                },
            ],
        },
        {
            path: trimSlash(ROUTES.mchd),
            element: <MchdTab />,
        },
        {
            path: trimSlash(ROUTES.archive),
            element: <Archive />,
        },
    ],
};

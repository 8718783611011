import { IMaskInputProps } from 'react-imask';
import { FormItemProps } from 'antd/lib';

type ValidationItem = {
    item: FormItemProps;
    input: IMaskInputProps<HTMLInputElement> | HTMLInputElement;
};

export const CertificateTemplateValidation = {
    templateId: {
        item: {
            name: 'templateId',
            label: 'Назначение сертификата',
        },
        input: {
            placeholder: 'Выберите назначение сертификата',
        },
    },
    inn: {
        item: {
            name: 'inn',
            label: 'ИНН ЮЛ',
            extra: 'Только цифры, без пробелов и символов, 10 знаков',
            rules: [
                {
                    pattern: /^\d{10}$/,
                    message: 'Неверный формат',
                },
            ],
        },
        input: {
            placeholder: 'XXXXXXXXXX',
            mask: '0000000000',
        },
    },
    kpp: {
        item: {
            name: 'kpp',
            label: 'КПП',
            extra: 'Только цифры, без пробелов и символов, 9 знаков',
            rules: [
                {
                    pattern: /^\d{9}$/,
                    message: 'Неверный формат',
                },
            ],
        },
        input: {
            placeholder: 'XXXXXXXXX',
            mask: '000000000',
        },
    },
} satisfies Record<string, ValidationItem>;

import { useCallback } from 'react';

import { useResetCertificateChecksMutation } from '~/rtk-queries/endpoints/certificates-list';
import { isCertificatePreApplication } from '~/utils/is-certificate-variant';
import { Certificate } from '~/utils/transform-certificates-data/type';

export const useResetPreapplicationChecks = () => {
    const [resetCertificateChecks, { isLoading: isResetChecksLoading }] =
        useResetCertificateChecksMutation();

    const resetPreapplicationChecks = useCallback(
        async (data: Certificate) => {
            if (isCertificatePreApplication(data)) {
                await resetCertificateChecks(data.pkiUserId);
            }
        },
        [resetCertificateChecks],
    );

    return { resetPreapplicationChecks, isResetChecksLoading };
};

import { useCallback } from 'react';

import { useCheckCadesExtension } from '../use-check-cades-extension';

import { ModalType } from '~/constants/modal';
import { useAppDispatch } from '~/hooks';
import { openModal } from '~/store/slice/modal/modal-slice';
import { MODAL_VARIANT_FOR_PLUGIN } from '~/utils/plugin/constants';

export const usePluginChecker = () => {
    const { isInstalledEnviroment, errorType, installLink } = useCheckCadesExtension();
    const dispatch = useAppDispatch();
    const checkExtension = useCallback(async () => {
        if (isInstalledEnviroment) return true;

        if (errorType) {
            const { text, title } = MODAL_VARIANT_FOR_PLUGIN[errorType];

            dispatch(
                openModal({
                    text,
                    title,
                    isError: true,
                    type: ModalType.viewAnswer,
                    link: installLink,
                    errorType,
                }),
            );
        }

        return false;
    }, [isInstalledEnviroment, errorType, dispatch, installLink]);

    return {
        checkExtension,
    };
};

import { useLayoutEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/query';
import { FormInstance } from 'antd';
import dayjs from 'dayjs';

import { useApplicationModal } from '../use-application-modal';
import { useFormSearchQuery } from '../use-form-search-query';

import styles from './index.module.css';

import { ROUTES } from '~/app-router/routes';
import { InputValueType } from '~/constants/application-tab/certificate-form';
import { FormButtonLabel, UserInfoApiError } from '~/constants/application-tab/general';
import { DATE_FORMAT } from '~/constants/general';
import { HttpStatus } from '~/constants/http-status';
import { ModalTextVariant } from '~/constants/modal';
import { useGetDraftQuery, useRemoveDraftMutation } from '~/rtk-queries/endpoints/draft';
import { useGetUserInfoQuery } from '~/rtk-queries/endpoints/user-info';
import { InfoAttribute } from '~/rtk-queries/types/user-info';
import { setPopupAlert } from '~/store/slice/alert/alert-slice';
import { CertificateForm } from '~/types/application-tab/certificate-form';
import { Statuses } from '~/types/common';
import {
    isErrorWithDataMessage,
    isFetchBaseQueryError,
} from '~/utils/type-guard/is-fetch-base-query-error';

export const useInitialForm = (form: FormInstance<CertificateForm>) => {
    const formInitialized = useRef(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { openApplicationModal } = useApplicationModal();
    const { folderId, templateId, draftId, kpp, inn } = useFormSearchQuery();

    const {
        data: userInfoData,
        isLoading: isUserInfoLoading,
        isSuccess: isUserInfoSuccess,
        error: userInfoError,
    } = useGetUserInfoQuery(draftId ? skipToken : { folderId, templateId, kpp, inn });

    const {
        data: draftData,
        isSuccess: isDraftSuccess,
        isLoading: isDraftLoading,
        error: draftError,
    } = useGetDraftQuery(draftId ? { RequestId: draftId } : skipToken);
    const [removeDraft] = useRemoveDraftMutation();

    const formData = useMemo(() => {
        if (userInfoData) {
            return { blocks: userInfoData.userInfo.blocks, dictionaries: userInfoData.selectData };
        }

        if (draftData) {
            return { blocks: draftData.userInfoTemp.blocks, dictionaries: draftData.selectData };
        }

        return null;
    }, [userInfoData, draftData]);

    const { initialFormValues, enabledAttributeMap } = useMemo(() => {
        const flatAttributes: InfoAttribute[] = [];

        const blocks = draftId ? draftData?.userInfoTemp.blocks : userInfoData?.userInfo.blocks;

        if (!blocks?.length) {
            return { initialFormValues: {}, enabledAttributeMap: {} };
        }

        blocks?.forEach(({ attributes }) => {
            flatAttributes.push(...attributes);
        });

        const formValues: CertificateForm = {};
        const enabledHashmap: Record<string, boolean> = {};

        flatAttributes.forEach(({ oid, value, valueType, enabled }) => {
            if (valueType === InputValueType.Date) {
                const dateObj = dayjs(value, DATE_FORMAT);

                formValues[oid] = dateObj.isValid() ? dateObj : null;
            } else {
                formValues[oid] = value;
            }

            enabledHashmap[oid] = enabled;
        });

        return { initialFormValues: formValues, enabledAttributeMap: enabledHashmap };
    }, [userInfoData, draftData, draftId]);

    useLayoutEffect(() => {
        if (formInitialized.current) {
            return;
        }

        if (Object.keys(initialFormValues).length && (isUserInfoSuccess || isDraftSuccess)) {
            form.setFieldsValue(initialFormValues);
            formInitialized.current = true;
        }
    }, [isUserInfoSuccess, isDraftSuccess, form, initialFormValues]);

    useLayoutEffect(() => {
        const error = userInfoError ?? draftError;
        const isServerError =
            isFetchBaseQueryError(error) &&
            isErrorWithDataMessage(error) &&
            error.status === HttpStatus.INTERNAL_SERVER_ERROR;

        if (isServerError) {
            const isTemplateNotExist = error.data.message?.includes(
                UserInfoApiError.notExist.pattern,
            );

            if (isTemplateNotExist) {
                const routeTo = `${ROUTES.privateRoom}${ROUTES.certificateTemplate}`;

                navigate(routeTo, {
                    replace: true,
                });

                if (draftId) {
                    openApplicationModal({
                        title: ModalTextVariant.attention,
                        content: ModalTextVariant.errorTemplateMessage,
                        icon: <DeleteOutlined className={styles.modalIcon} />,
                        okText: FormButtonLabel.removeDraft.toLocaleUpperCase(),
                        onOk: () => removeDraft({ RequestId: draftId }),
                    });
                } else {
                    dispatch(
                        setPopupAlert({
                            type: Statuses.ERROR,
                            message: UserInfoApiError.notExist.message,
                            keepOnRoute: [routeTo],
                        }),
                    );
                }
            }
        }
    }, [navigate, dispatch, userInfoError, draftError, openApplicationModal, removeDraft, draftId]);

    return {
        formData,
        isInitialLoading: isUserInfoLoading || isDraftLoading,
        enabledAttributeMap,
    };
};

import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/locale/ru_RU';

import styles from './app.module.css';

import { AppRouter } from '~/app-router';
import { useCookiesNotification } from '~/hooks/use-cookies-notification';

export const App = () => {
    const { noticeCookies, context } = useCookiesNotification();

    useEffect(() => {
        window.process = {
            ...window.process,
            nextTick: () => {},
        };

        noticeCookies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.content}>
            <ConfigProvider locale={ru_RU}>
                {context}
                <RouterProvider router={AppRouter} />
            </ConfigProvider>
        </div>
    );
};

import { removeLocalStorageItem } from './local-storage';

import { LOCAL_STORAGE } from '~/constants/local-storage';

export const clearAuthLocalStorage = () => {
    removeLocalStorageItem(LOCAL_STORAGE.authType);
    removeLocalStorageItem(LOCAL_STORAGE.sessionId);
    removeLocalStorageItem(LOCAL_STORAGE.thumbprint);
    removeLocalStorageItem(LOCAL_STORAGE.filtersForArchives);
    removeLocalStorageItem(LOCAL_STORAGE.filtersForCertificates);
};

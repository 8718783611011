export enum SignDocumentText {
    uploadFile = 'Выберите файл, который необходимо подписать',
    buttonText = 'Выберите файл',
    attention = 'Внимание! Мы не храним и не используем Ваши файлы.',
    header = 'Сервис подписания документа электронной подписью',
    empty = 'Сертификаты не обнаружены. Подключите носитель к ПК и перезагрузите страницу.',
    aborted = 'Доступ к сертификатам не был предоставлен. Перезагрузите страницу и нажмите "Да" в окне подтверждения доступа.',
    reload = 'Перезагрузите страницу и разрешите эту операцию.',
    certificate = 'Выберите электронную подпись для подписания документа',
}

export enum SignatureTooltip {
    certificate = 'Если нужного сертификата нет в списке, выполните следующие действия: откройте на компьютере программу "КриптоПро CSP" → перейдите на вкладку "Сервис" → нажмите кнопку "Просмотреть сертификаты в контейнере" → "Обзор" → выберите нужный контейнер (носитель с ключом должен быть подключен к компьютеру) → "Установить" → вернитесь в браузер и обновите страницу → выберите нужный сертификат.',
    signature = 'Выберите вид подписи: присоединённая или откреплённая. Присоединённая ЭП - в итоговом файле будет исходный документ с подписью. Откреплённая ЭП - в итоговом файле будет только подпись.',
}

export enum SignatureType {
    attached = 'Присоединённая подпись',
    detached = 'Откреплённая подпись',
}

export const SIGNATURE_TYPE_VARIANT = {
    attached: {
        value: 0,
        label: SignatureType.attached,
    },
    detached: {
        value: 1,
        label: SignatureType.detached,
    },
};

export const SIGNATURE_TYPE_OPTIONS = [
    SIGNATURE_TYPE_VARIANT.detached,
    SIGNATURE_TYPE_VARIANT.attached,
];

export const MAX_SIZE_DOCUMENT_FOR_SIGN = 50;

export const CHECK_FILE_SIZE_MB = 10;
export const AVAILABLE_FILE_TYPES = 'Доступные для загрузки файлы *.cer, *.crt, *.p7b, *.pem.';

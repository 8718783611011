export enum ButtonType {
    button = 'button',
    reset = 'reset',
    submit = 'submit',
    link = 'link',
}

export const BUTTON_TEXT = {
    sendRequest: 'Отправить данные на проверку',
    saveDraft: 'Сохранить как черновик',
    checkCertificate: 'Проверить',
    signFile: 'Подписать файл',
    downloadSignedFile: 'Скачать файл с подписью',
    signNewFile: 'Подписать другой файл',
    checkAnotherCertificate: 'Проверить другой сертификат',
    checkAnotherDocument: 'Проверить другой документ',
    publicServices: 'Войти через Госуслуги',
    electronicSignature: 'Электронная подпись',
    otherLogin: 'Другие способы входа',
    installExtension: 'Установить расширение',
    downloadPlugin: 'Загрузить плагин',
    downloadCsp: 'Загрузить КриптоПро CSP',
    checkElectronicSignature: 'Носитель подключен',
    yes: 'Да',
    no: 'Нет',
    ok: 'Ок',
    cancel: 'Отмена',
    continue: 'Продолжить',
    update: 'Обновить',
    send: 'Отправить',
    print: 'Печать',
    back: 'Назад к выбору назначения сертификата',
    loadPlugin: 'Выполняется проверка плагина',
};

import { ColumnsType } from 'antd/es/table';

import { TableResult, TableResultColumn } from '../../../../types/types';

export const returnColumns = (): ColumnsType<TableResultColumn> => [
    {
        key: TableResult.columnName,
        title: TableResult.columnName,
        dataIndex: TableResult.columnName,
        width: '50%',
    },
    {
        key: TableResult.columnResult,
        title: TableResult.columnResult,
        dataIndex: TableResult.columnResult,
        width: '50%',
    },
];

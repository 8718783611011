import { FormInstance } from 'antd';

import { returnOptions } from '../helpers/return-options';
import { CheckCertificateFields } from '../types/form-types';

import styles from './additional-checks.module.css';

import { useFirstRender } from '~/hooks/use-first-render';
import { CertificateVerifier } from '~/rtk-queries/types/certificate-verification';
import { CustomCheckboxGroup } from '~/shared/form-components/custom-checkbox-group';

type AdditionalChecksProps = {
    form: FormInstance;
    certificateVerifiers: CertificateVerifier[];
    disabled?: boolean;
};

export const AdditionalChecks = ({
    form,
    certificateVerifiers,
    disabled,
}: AdditionalChecksProps) => {
    const { options, defaultValue } = returnOptions(certificateVerifiers);
    const isFirstRender = useFirstRender();

    if (isFirstRender) {
        form.setFieldValue(CheckCertificateFields.certVerifiersPluginsIds, defaultValue);
    }

    return (
        <CustomCheckboxGroup
            label='Дополнительные проверки'
            defaultValue={defaultValue}
            name={CheckCertificateFields.certVerifiersPluginsIds}
            options={options}
            className={styles.checkBoxGroup}
            disabled={disabled}
        />
    );
};

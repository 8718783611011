import { CollapseProps } from 'antd';

import { AdditionalResult } from '../../shared/additional-result';
import { CertificateInfo } from '../../shared/certificate-info';
import { MainInfo } from '../result-block/main-info';

import styles from './return-items-for-collapse.module.css';

import { VerificationResult } from '~/rtk-queries/types/certificate-verification';

type CollapseItem = Required<CollapseProps>['items'][number];

export const returnItemsForCollapse = (
    fileName: string,
    verifyCertificate?: VerificationResult,
): CollapseProps['items'] => {
    if (!verifyCertificate) return [];

    const createItem = (key: string, label: string, children: React.ReactNode): CollapseItem => ({
        key,
        label,
        children,
        showArrow: true,
        headerClass: styles.collapseHeader,
        className: styles.collapseContainer,
    });

    const { result, message, additionalCertificateResult, signerCertificateInfo } =
        verifyCertificate;

    const itemsForCollapse: CollapseItem[] = [
        createItem(
            'Основная информация',
            'Основная информация',
            <MainInfo result={result} fileName={fileName} message={message} />,
        ),
    ];

    if (signerCertificateInfo) {
        itemsForCollapse.push(
            createItem(
                'Информация о сертификате',
                'Информация о сертификате',
                <CertificateInfo result={result} info={signerCertificateInfo} />,
            ),
        );
    }

    additionalCertificateResult?.forEach(({ pluginDescription, errorsList, bResult }) => {
        itemsForCollapse.push(
            createItem(
                pluginDescription,
                pluginDescription,
                <AdditionalResult errors={errorsList} result={bResult} />,
            ),
        );
    });

    return itemsForCollapse;
};

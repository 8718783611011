import { api } from '../api';
import { API } from '../constants/api';
import { transformErrorWithAlert } from '../helpers/transform-error-with-alert';

import { AlertMessage } from '~/constants/alert-message';
import { CommonResponse } from '~/types/common';
import { getDisplayNameByOID } from '~/utils/get-display-name-by-oid/get-display-name-by-oid';
import { NamePolicyType } from '~/utils/get-display-name-by-oid/type';

export const namePolicy = api.injectEndpoints({
    endpoints: (builder) => ({
        getNamePolicy: builder.query<Record<string, string>, { folderName: string }>({
            query: (params) => ({
                url: API.namePolicy,
                method: 'GET',
                params,
            }),
            transformResponse: ({
                namePolicy: namePolicyResponse,
            }: CommonResponse<NamePolicyType>) =>
                getDisplayNameByOID(namePolicyResponse.nameAttributes),

            transformErrorResponse: (response) =>
                transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
        }),
    }),
});

export const { useLazyGetNamePolicyQuery } = namePolicy;

import { AuthTypeLabel, DRAFT_FORM_TOOLTIP } from '~/constants/auth-draft';
import { AuthType } from '~/constants/auth-type';
import { LOCAL_STORAGE } from '~/constants/local-storage';

type LabelKey = Exclude<AuthType, 0>;

export const getDraftButtonTooltip = () => {
    const labelKey = Number(localStorage.getItem(LOCAL_STORAGE.authType)) as LabelKey;

    return `${DRAFT_FORM_TOOLTIP} ${AuthTypeLabel[labelKey]}`;
};

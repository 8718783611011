import { FC } from 'react';
import { Flex, Form, FormInstance } from 'antd';

import { MAX_SIZE_FILE_DOC } from '../../constants/file-upload';
import { SignatureSelection } from '../../constants/signature-selection-options';
import { CheckDocumentsFields, FormTypes } from '../../types/form-types';

import styles from '../../check-document.module.css';

import { FORMS_DATA } from '~/constants/forms-data';
import { MAX_SIZE_DOCUMENT_FOR_SIGN } from '~/constants/sign-document';
import { FormUpload } from '~/shared/files-upload';
import { ConfigRules } from '~/utils/get-front-config/type';

type CheckDocumentUploadProps = {
    form: FormInstance<FormTypes>;
    rules?: ConfigRules;
    disabledFormElements?: boolean;
    loading?: boolean;
};

export const CheckDocumentUpload: FC<CheckDocumentUploadProps> = ({
    disabledFormElements = false,
    rules,
    loading,
    form,
}) => {
    const currentSignatureSelect = Form.useWatch(CheckDocumentsFields.signatureSelection, form);

    const needOneFileUpload =
        currentSignatureSelect && currentSignatureSelect === SignatureSelection.pinnedSignature;

    const disabledUpload = disabledFormElements && !needOneFileUpload;

    const fileSizeWithSign = Number(rules?.VerifLoadSig) || MAX_SIZE_DOCUMENT_FOR_SIGN;
    const fileSizeDoc = Number(rules?.VerifLoadFile) || MAX_SIZE_FILE_DOC;

    return currentSignatureSelect ? (
        <Flex vertical={true} gap={16} className={styles.uploadGroup}>
            <FormUpload
                buttonText={FORMS_DATA.checkDocumentForm.uploadOneFile}
                name={CheckDocumentsFields.content}
                title={<h4>{FORMS_DATA.checkDocumentForm.signatureTitle}</h4>}
                disabledUploadButton={disabledFormElements}
                isRequired={true}
                fileListBlock={true}
                hideUploadButtonAfterLastFileUploaded={true}
                isLoading={loading}
                multiple={false}
                maxCount={1}
                maxFileSizeMb={fileSizeWithSign}
                minCount={1}
                showExtensionIcon={true}
                currentSignatureSelect={currentSignatureSelect}
            />

            {!needOneFileUpload && (
                <FormUpload
                    buttonText={FORMS_DATA.checkDocumentForm.uploadTwoFiles}
                    name={CheckDocumentsFields.source}
                    title={<h4>{FORMS_DATA.checkDocumentForm.uploadTitle}</h4>}
                    fileListWidth={300}
                    disabledUploadButton={disabledUpload}
                    isRequired={true}
                    fileListBlock={true}
                    hideUploadButtonAfterLastFileUploaded={true}
                    isLoading={loading}
                    multiple={false}
                    maxCount={1}
                    maxFileSizeMb={fileSizeDoc}
                    minCount={1}
                    showExtensionIcon={true}
                    currentSignatureSelect={currentSignatureSelect}
                />
            )}
        </Flex>
    ) : null;
};

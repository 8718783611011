import { AuditOutlined, HighlightOutlined, SearchOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/es/menu/interface';

import { ROUTES } from '~/app-router/routes';

export const URL_PARAM_LEVEL = 1;

export const OPTIONS_FOR_TAB = {
    checkCertificate: 'Проверка сертификата',
    checkDocument: 'Проверка электронной подписи',
    signDocument: 'Подписание документа',
};

export const ValidationTabItem: ItemType[] = [
    {
        key: ROUTES.checkCertificate,
        icon: <SearchOutlined />,
        label: OPTIONS_FOR_TAB.checkCertificate,
    },
    {
        key: ROUTES.checkDocument,
        icon: <AuditOutlined />,
        label: OPTIONS_FOR_TAB.checkDocument,
    },
    {
        key: ROUTES.signDocument,
        icon: <HighlightOutlined />,
        label: OPTIONS_FOR_TAB.signDocument,
    },
];

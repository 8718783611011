import { useSearchParams } from 'react-router-dom';

import { ApplicationFormQuery } from '~/app-router/routes';

export const useFormSearchQuery = () => {
    const [searchParams] = useSearchParams();
    const folderId = Number(searchParams.get(ApplicationFormQuery.folderId));
    const templateId = Number(searchParams.get(ApplicationFormQuery.templateId));
    const draftId = Number(searchParams.get(ApplicationFormQuery.draftId)) || null;
    const kpp = Number(searchParams.get(ApplicationFormQuery.kpp));
    const inn = Number(searchParams.get(ApplicationFormQuery.inn));
    const authorityName = searchParams.get(ApplicationFormQuery.authorityName) ?? '';

    return { folderId, templateId, draftId, kpp, inn, authorityName };
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LOCAL_STORAGE } from '~/constants/local-storage';

const initialState = {
    isAuthenticated: !!Number(localStorage.getItem(LOCAL_STORAGE.authType)),
};

export const authSlice = createSlice({
    name: 'auth-slice',
    initialState,
    reducers: {
        setAuthenticated: (state, actions: PayloadAction<boolean>) => {
            state.isAuthenticated = actions.payload;
        },
        resetAuth: () => ({
            isAuthenticated: false,
        }),
    },
    selectors: {
        isAuthenticatedSelector: (state) => state.isAuthenticated,
    },
});

export type AuthState = typeof initialState;
export const { setAuthenticated, resetAuth } = authSlice.actions;
export const { isAuthenticatedSelector } = authSlice.selectors;
export const authReducer = authSlice.reducer;

import { useCallback } from 'react';
import { UploadFile } from 'antd/es/upload';

import { getVerifyParams } from '../check-document/helpers/get-verify-params';
import { returnSignatureType } from '../check-document/helpers/return-signature-type';
import { Signature } from '../check-document/types/signature';

import { useCheckSignatureDocumentMutation } from '~/rtk-queries/endpoints/certificate-verification';
import { SignatureDescription } from '~/rtk-queries/types/certificate-verification';

type HandleAutoInstallParams = {
    base64Array: Array<string | null>;
    isDetachedSignature: boolean;
    content: UploadFile[];
    certVerifiersPluginsIds?: Array<number>;
};

type HandleManualVerificationParams = Omit<HandleAutoInstallParams, 'content'> & {
    isDataSignature: boolean;
    isGOST3410: boolean;
    base64Certificate: Array<string | null>;
    hashFunctionSelection?: string;
    signatureType?: string;
};

export const useCheckSignatureDocument = (signatureDescriptions: Array<SignatureDescription>) => {
    const [
        checkSignatureDocument,
        { data: signatureDocument, isLoading: isLoadingCheckSignatureDocument, isSuccess, isError },
    ] = useCheckSignatureDocumentMutation();

    const handleAutoInstall = useCallback(
        ({
            base64Array,
            isDetachedSignature,
            certVerifiersPluginsIds,
            content,
        }: HandleAutoInstallParams) => {
            if (base64Array.length > 1) {
                checkSignatureDocument({
                    content: base64Array[0],
                    signatureType: Signature.CMS,
                    certificate: null,
                    certVerifiersPluginsIds,
                    source: base64Array[1],
                    verifyParams: { isDetached: isDetachedSignature },
                });
            } else if (base64Array.length === 1) {
                const currentSignatureType = returnSignatureType(
                    content[0].name,
                    signatureDescriptions,
                );

                checkSignatureDocument({
                    content: base64Array[0],
                    signatureType: currentSignatureType,
                    certificate: null,
                    certVerifiersPluginsIds,
                    source: null,
                    verifyParams: { isDetached: isDetachedSignature },
                });
            }
        },
        [checkSignatureDocument, signatureDescriptions],
    );

    const handleManualVerification = useCallback(
        ({
            base64Array,
            isDetachedSignature,
            isDataSignature,
            signatureType,
            isGOST3410,
            base64Certificate,
            certVerifiersPluginsIds,
            hashFunctionSelection,
        }: HandleManualVerificationParams) => {
            const verifyParams = getVerifyParams({
                isDataSignature,
                isDetachedSignature,
                hashFunctionSelection,
                isGOST3410,
            });

            if (base64Array.length > 1) {
                checkSignatureDocument({
                    content: base64Array[0],
                    signatureType,
                    certificate: isGOST3410 ? base64Certificate![1] : null,
                    certVerifiersPluginsIds,
                    source: base64Array[1],
                    verifyParams,
                });
            } else {
                delete verifyParams.hash;
                checkSignatureDocument({
                    content: base64Array[0],
                    signatureType,
                    certificate: isGOST3410 ? base64Certificate![1] : null,
                    certVerifiersPluginsIds,
                    source: null,
                    verifyParams,
                });
            }
        },
        [checkSignatureDocument],
    );

    return {
        isLoadingCheckSignatureDocument,
        isSuccess,
        isError,
        signatureDocument,
        handleAutoInstall,
        handleManualVerification,
    };
};

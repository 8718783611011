import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useArchiveAndSaveActions } from './use-archive-and-save-actions';
import { useDraftActions } from './use-draft-actions';
import { useGenerateKeys } from './use-generate-keys';
import { useInstallCertificate } from './use-install-certificate';
import { useResetPreapplicationChecks } from './use-reset-preapplication-checks';
import { useRevokeCertificate } from './use-revoke-certificate';

import { ROUTES } from '~/app-router/routes';
import { BASE_PARAMS } from '~/components/certificates-table/constants/general';
import { ActionType } from '~/constants/certificates';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { ModalTextVariant, ModalType } from '~/constants/modal';
import { useAppDispatch } from '~/hooks';
import { openModal } from '~/store/slice/modal/modal-slice';
import { Certificate } from '~/utils/transform-certificates-data/type';

export const useCertificateAction = (reason: number, currentCertificate: Certificate) => {
    const dispatch = useAppDispatch();

    const {
        continueDraft,
        deleteDraft,
        isSuccessRemoveDraft,
        isLoadingLoadingRemoveDraft,
        resetRemoveDraft,
    } = useDraftActions();

    const {
        sendToArchive,
        saveToFile,
        isFetchingPKIDetailsInSave,
        isSuccessSendToArchive,
        isLoadingSendToArchive,
        resetSendToArchive,
    } = useArchiveAndSaveActions();

    const {
        openRevokeModal,
        revokeRequest,
        isRevokeRequest,
        setIsRevokeRequest,
        isSuccessRevokeCertificate,
        isLoadingRevoke,
        resetRevokeCertificate,
    } = useRevokeCertificate({ reason, currentCertificate });

    const {
        openGenerateKeysModal,
        generateKeys,
        isSuccessApplyRequest,
        isLoadingApplyRequest,
        resetApplyRequest,
        isFetchingTemplate,
    } = useGenerateKeys({ currentCertificate });

    const { getDetailsForInstall, isFetchingPKIDetailsInInstall } = useInstallCertificate();

    const { resetPreapplicationChecks, isResetChecksLoading } = useResetPreapplicationChecks();

    const navigate = useNavigate();

    const isFetchingDetails =
        isLoadingRevoke ||
        isFetchingPKIDetailsInInstall ||
        isFetchingPKIDetailsInSave ||
        isFetchingTemplate ||
        isLoadingApplyRequest ||
        isLoadingSendToArchive ||
        isLoadingLoadingRemoveDraft ||
        isResetChecksLoading;

    const openAnswerModal = () => {
        dispatch(
            openModal({
                isError: false,
                type: ModalType.viewAnswer,
                title: ModalTextVariant.answer,
            }),
        );
    };

    const actionHandler = {
        [ActionType.continueDraft]: continueDraft,
        [ActionType.deleteDraft]: deleteDraft,
        [ActionType.installCertificate]: (data: Certificate) => getDetailsForInstall(data),
        [ActionType.keyGeneration]: openGenerateKeysModal,
        [ActionType.revokeCertificate]: openRevokeModal,
        [ActionType.viewAnswer]: openAnswerModal,
        [ActionType.sendToArchive]: (data: Certificate) => sendToArchive(data),
        [ActionType.resetChecks]: (data: Certificate) => resetPreapplicationChecks(data),
        [ActionType.saveToFile]: (data: Certificate) => saveToFile(data),
    };

    useEffect(() => {
        if (isSuccessApplyRequest || isSuccessRevokeCertificate || isSuccessRemoveDraft) {
            localStorage.removeItem(LOCAL_STORAGE.filtersForCertificates);
            navigate(`${ROUTES.privateRoom}${ROUTES.certificateTable}${BASE_PARAMS}`);
            resetRevokeCertificate();
            resetApplyRequest();
            resetRemoveDraft();
        }
        if (isSuccessSendToArchive) {
            resetSendToArchive();
        }
    }, [
        isSuccessApplyRequest,
        isSuccessRevokeCertificate,
        isSuccessSendToArchive,
        navigate,
        resetApplyRequest,
        resetRevokeCertificate,
        resetSendToArchive,
        resetRemoveDraft,
        isSuccessRemoveDraft,
    ]);

    return {
        actionHandler,
        revokeRequest,
        generateKeys,
        isFetchingDetails,
        isRevokeRequest,
        setIsRevokeRequest,
    };
};

export const checkCadesStatus = (handler: () => Promise<void>) => {
    const { cadesplugin } = window;
    const canPromise = !!window.Promise;

    if (!cadesplugin) return;
    if (canPromise) {
        cadesplugin
            .then(() => {
                handler();
            })
            .catch((e: unknown) => {
                console.error('сообщение об ошибке:', e);
            });
    } else {
        window.addEventListener(
            'message',
            (event) => {
                if (event.data === 'cadesplugin_loaded') {
                    console.info('прикладной код cadesplugin_loaded');
                    handler();
                    // прикладной код
                } else if (event.data === 'cadesplugin_load_error') {
                    console.error('cadesplugin_load_error');
                    // сообщение об ошибке
                }
            },
            false,
        );
        window.postMessage('cadesplugin_echo_request', '*');
    }
};

import {
    CaretDownOutlined,
    InfoCircleOutlined,
    LogoutOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import classNames from 'classnames';

import { HEADER } from '../../constants/header';
import { MenuItemButton } from '../menu-item-button/menu-item-button';

import styles from './header.module.css';

import { AuthType } from '~/constants/auth-type';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { useAppDispatch } from '~/hooks';
import { api } from '~/rtk-queries/api';
import {
    useGetLogoutEsiaUrlMutation,
    usePostLogoutMutation,
} from '~/rtk-queries/endpoints/authentication';
import { useGetFrontConfigQuery } from '~/rtk-queries/endpoints/front-config';
import { useGetUserNameQuery } from '~/rtk-queries/endpoints/user-info';
import { LogoBlock } from '~/shared/logo';
import { resetAuth } from '~/store/slice/auth/auth-slice';
import { clearAuthLocalStorage } from '~/utils/clear-auth-local-storage';
import { FrontConfigLinksKey } from '~/utils/get-front-config/type';

export const Header = () => {
    const [postLogout] = usePostLogoutMutation();
    const [postLogoutEsia] = useGetLogoutEsiaUrlMutation();

    const dispatch = useAppDispatch();

    const { data: frontConfig } = useGetFrontConfigQuery();

    const { data: userNameData } = useGetUserNameQuery();

    const logOutHandler = async () => {
        const isEsiaAuth = Number(localStorage.getItem(LOCAL_STORAGE.authType)) === AuthType.Esia;

        try {
            let esiaLogoutUrl: string | undefined;

            if (isEsiaAuth) {
                esiaLogoutUrl = (await postLogoutEsia()).data?.esiaLogoutUrl;
            }

            await postLogout();

            if (isEsiaAuth && esiaLogoutUrl) {
                clearAuthLocalStorage();
                window.location.href = esiaLogoutUrl;
            } else {
                dispatch(resetAuth());
                dispatch(api.util.resetApiState());
            }
        } catch (error: unknown) {
            console.error(error);
        }
    };

    const headerMenu = [
        {
            key: 'info',
            Icon: InfoCircleOutlined,
            label: HEADER.information,
            items: [
                {
                    key: 0,
                    label: (
                        <MenuItemButton
                            href={frontConfig?.links[FrontConfigLinksKey.PSBSignatureLink]}
                        >
                            {HEADER.centerInformation}
                        </MenuItemButton>
                    ),
                },
                {
                    key: 1,
                    label: (
                        <MenuItemButton href={frontConfig?.links[FrontConfigLinksKey.FAQPageLink]}>
                            {HEADER.questions}
                        </MenuItemButton>
                    ),
                },
            ],
        },
        {
            key: 'profile',
            Icon: UserOutlined,
            label: userNameData?.name,
            items: [
                {
                    key: 0,
                    label: (
                        <MenuItemButton onClick={logOutHandler} icon={<LogoutOutlined />}>
                            {HEADER.logout}
                        </MenuItemButton>
                    ),
                },
            ],
        },
    ];

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            event.currentTarget.click();
        }
    };

    return (
        <header className={styles.header}>
            <LogoBlock type='dark' />

            <Space size='small'>
                {headerMenu.map(({ Icon, key, label, items }) => (
                    <Dropdown
                        menu={{ items, className: styles.menuDropdown }}
                        placement='bottomLeft'
                        getPopupContainer={(parent) => parent}
                        destroyPopupOnHide={true}
                        trigger={['click']}
                        {...{ key }}
                    >
                        <div
                            className={styles.menuButton}
                            role='button'
                            onKeyDown={handleKeyDown}
                            tabIndex={0}
                        >
                            <Icon />
                            {label}
                            <CaretDownOutlined className={classNames(styles.menuButtonCaret)} />
                        </div>
                    </Dropdown>
                ))}
            </Space>
        </header>
    );
};

import { FC, useMemo } from 'react';
import { Form } from 'antd';

import { InputValueType } from '~/constants/application-tab/certificate-form';
import { InfoAttribute, SelectDictionary } from '~/rtk-queries/types/user-info';
import { CustomDataPicker } from '~/shared/form-components/custom-data-picker';
import { CustomInput } from '~/shared/form-components/custom-input';
import { CustomSelect } from '~/shared/form-components/custom-select';
import { CustomSelectOption } from '~/shared/form-components/custom-select/custom-select';
import { createValidationRules } from '~/utils/application-tab/create-validation-rules';
import { trimSpaces } from '~/utils/application-tab/trim-spaces';

type PolymprphicInputProps = {
    attribute: InfoAttribute;
    dictionaries: SelectDictionary[];
};

export const PolymprphicInput: FC<PolymprphicInputProps> = ({
    attribute: {
        value,
        displayName: label,
        enabled,
        oid,
        regex,
        valueType,
        minLength,
        maxLength,
        required,
    },
    dictionaries,
}) => {
    const form = Form.useFormInstance();

    const options = useMemo<CustomSelectOption[]>(() => {
        const [_, dictionaryKey] = valueType.split('.');

        if (dictionaryKey) {
            const matchedOptions = dictionaries.find(
                ({ selectName }) => selectName === dictionaryKey,
            );

            return matchedOptions?.values ?? [];
        }

        return [];
    }, [dictionaries, valueType]);

    const rules = createValidationRules(
        { minLength, maxLength, regex, enabled, required },
        valueType,
    );

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) =>
        form.setFieldValue(oid, trimSpaces(event.target.value));

    if (valueType.startsWith(InputValueType.Select)) {
        return (
            <CustomSelect key={oid} name={oid} disabled={!enabled} {...{ label, rules, options }} />
        );
    }

    switch (valueType) {
        case InputValueType.Date:
            return (
                <CustomDataPicker key={oid} name={oid} disabled={!enabled} {...{ label, rules }} />
            );

        case InputValueType.Integer:
        case InputValueType.String:
            return (
                <CustomInput
                    key={oid}
                    name={oid}
                    disabled={!enabled}
                    onBlur={handleBlur}
                    {...{ label, rules, value, maxLength, minLength }}
                />
            );

        default:
            return (
                <CustomInput
                    key={oid}
                    name={oid}
                    disabled={!enabled}
                    onBlur={handleBlur}
                    {...{ label, rules, value, maxLength, minLength }}
                />
            );
    }
};

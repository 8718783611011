import { Middleware } from '@reduxjs/toolkit';

import { resetAuth } from '../slice/auth/auth-slice';

import { clearAuthLocalStorage } from '~/utils/clear-auth-local-storage';

export const logoutMiddleware: Middleware = () => (next) => (action) => {
    if (resetAuth.match(action)) {
        clearAuthLocalStorage();
    }

    return next(action);
};

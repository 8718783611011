import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';

import { APPLICATION_TITLE_MODAL } from '../constants/application-tab-modal';

import { ROUTES } from '~/app-router/routes';
import { useAppDispatch } from '~/hooks';
import { setNavigationDisabled } from '~/store/slice/app-tab/app-tab-slice';

export const useApplicationTabModal = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [modal, contextHolder] = Modal.useModal();

    const onOkHandler = useCallback(
        (tabKey: string, params: string) => {
            dispatch(setNavigationDisabled(false));
            navigate(`${ROUTES.privateRoom}${tabKey}${params}`);
        },
        [dispatch, navigate],
    );

    const showApplicationModal = useCallback(
        (tabKey: string, params: string) => {
            modal.confirm({
                title: <span style={{ whiteSpace: 'pre-wrap' }}>{APPLICATION_TITLE_MODAL}</span>,
                closable: true,
                icon: <ExclamationCircleFilled />,
                centered: true,
                okText: 'Перейти',
                cancelText: 'Отмена',
                okButtonProps: {
                    danger: true,
                },
                onOk() {
                    onOkHandler(tabKey, params);
                },
            });
        },
        [modal, onOkHandler],
    );

    return { contextHolder, showApplicationModal };
};

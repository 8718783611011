import { Rule, RuleObject } from 'antd/es/form';
import { isDayjs } from 'dayjs';

import { InputValueType } from '~/constants/application-tab/certificate-form';
import { DATE_FORMAT, ValidationRules } from '~/constants/general';
import { AttributeType } from '~/rtk-queries/types/user-info';
import { RulesSetup } from '~/types/application-tab/certificate-form';

export const createValidationRules = (
    { maxLength, minLength, regex, required }: RulesSetup,
    type: AttributeType,
) => {
    const rules: Rule[] = [];

    if (required) {
        rules.push(ValidationRules.required);
    }

    if (regex) {
        if (type === InputValueType.Date) {
            const validator: RuleObject['validator'] = (_, value) => {
                if (isDayjs(value)) {
                    const isValid = new RegExp(regex).test(value.format(DATE_FORMAT));

                    return isValid
                        ? Promise.resolve()
                        : Promise.reject(new Error('Некорректная дата'));
                }

                return Promise.reject(new Error('Некорректная дата'));
            };

            rules.push({ validator });
        } else {
            rules.push({
                pattern: new RegExp(regex),
                message: 'Не соответствует формату',
            });
        }
    }

    if (minLength) {
        rules.push({
            min: minLength,
            message: `Минимальная длина поля ${minLength} символов`,
        });
    }

    if (maxLength) {
        rules.push({
            max: maxLength,
            message: `Максимальная длина поля ${maxLength} символов`,
        });
    }

    return rules;
};

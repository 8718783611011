import { TabsProps } from 'antd';

import { ROUTES } from '~/app-router/routes';

export const AccountTabLabel = {
    applicationTab: 'Новый сертификат',
    certificateTab: 'Перечень сертификатов',
    checkCertificateTab: 'Подписание и проверка',
    mchdTab: 'МЧД',
    archiveTab: 'Журнал',
};

export const PrivateRoomTabItem: TabsProps['items'] = [
    {
        key: ROUTES.certificateTemplate,
        label: AccountTabLabel.applicationTab,
        destroyInactiveTabPane: true,
    },
    {
        key: ROUTES.certificateTable,
        label: AccountTabLabel.certificateTab,
    },
    {
        key: ROUTES.validation,
        label: AccountTabLabel.checkCertificateTab,
        destroyInactiveTabPane: true,
    },
    {
        key: ROUTES.mchd,
        label: AccountTabLabel.mchdTab,
        destroyInactiveTabPane: true,
    },
    {
        key: ROUTES.archive,
        label: AccountTabLabel.archiveTab,
    },
];

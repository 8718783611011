import { List, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import classNames from 'classnames';

import { TableResult, TableResultColumn } from '../../../types/types';

import styles from '~/shared/result-block-with-collapse/result-block-with-collapse.module.css';

export const returnColumns = (result: boolean): ColumnsType<TableResultColumn> => [
    {
        key: TableResult.columnName,
        title: TableResult.columnName,
        dataIndex: TableResult.columnName,
        width: '50%',
    },
    {
        key: TableResult.columnResult,
        title: TableResult.columnResult,
        dataIndex: TableResult.columnResult,
        width: '50%',
        render: (value, _, index) => {
            const condition = index === 0;
            const isArray = Array.isArray(value);

            return isArray ? (
                <List
                    dataSource={value}
                    renderItem={(error, i) => (
                        <List.Item key={i}>
                            <Typography.Text className={styles.listItem}>{error}</Typography.Text>
                        </List.Item>
                    )}
                />
            ) : (
                <span
                    className={classNames({
                        [styles.textSuccessColor]: condition && result,
                        [styles.textErrorColor]: condition && !result,
                    })}
                >
                    {value}
                </span>
            );
        },
    },
];

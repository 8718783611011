import { api } from '../api';
import { Tags } from '../constants/tags';
import { transformErrorWithAlert } from '../helpers/transform-error-with-alert';

import { AlertMessage } from '~/constants/alert-message';
import { AuthType } from '~/constants/auth-type';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { API } from '~/rtk-queries/constants/api';
import {
    EsiaAuthUrlResponse,
    LoginByCertificateRequest,
    LoginByEsiaRequest,
    LogoutEsiaResponse,
    SessionDataResponse,
} from '~/rtk-queries/types/auth';
import { setAuthenticated } from '~/store/slice/auth/auth-slice';
import { CommonResponse } from '~/types/common';

export const authentication = api
    .enhanceEndpoints({
        addTagTypes: [Tags.ENSURE_SESSION, Tags.ESIA_LOGIN_URL, Tags.USER_NAME],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            ensureSession: builder.mutation<CommonResponse<SessionDataResponse>, void>({
                query: () => ({
                    url: API.EnsureSession,
                    method: 'POST',
                }),
                async onQueryStarted(_, { queryFulfilled }) {
                    try {
                        const sessionData = await queryFulfilled;

                        localStorage.setItem(
                            LOCAL_STORAGE.sessionId,
                            String(sessionData.data.sessionId),
                        );
                    } catch (error) {
                        console.error(error);
                    }
                },
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
            }),

            getEsiaLogin: builder.query<CommonResponse<EsiaAuthUrlResponse>, void>({
                query: () => ({
                    url: API.getEsiaLoginUrl,
                    method: 'GET',
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
                providesTags: [Tags.ESIA_LOGIN_URL],
            }),

            postLoginByEsia: builder.mutation<CommonResponse, LoginByEsiaRequest>({
                query: (data) => ({
                    url: API.loginByEsia,
                    method: 'POST',
                    body: data,
                }),
                async onQueryStarted(_, { queryFulfilled, dispatch }) {
                    try {
                        await queryFulfilled;
                        dispatch(setAuthenticated(true));
                        localStorage.setItem(LOCAL_STORAGE.authType, String(AuthType.Esia));
                    } catch (error) {
                        console.error(error);
                    }
                },
                invalidatesTags: (_, error) =>
                    error ? [Tags.ENSURE_SESSION, Tags.ESIA_LOGIN_URL] : [Tags.USER_NAME],
            }),

            postLogout: builder.mutation<CommonResponse, void>({
                query: () => ({
                    url: API.logout,
                    method: 'POST',
                }),
            }),

            getLogoutEsiaUrl: builder.mutation<CommonResponse<LogoutEsiaResponse>, void>({
                query: () => ({
                    url: API.logoutEsia,
                    method: 'POST',
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
            }),

            refreshToken: builder.mutation<CommonResponse, { sessionId: number }>({
                query: (body) => ({
                    url: API.refreshToken,
                    method: 'POST',
                    body,
                }),
            }),

            authByCert: builder.mutation<CommonResponse, LoginByCertificateRequest>({
                query: (body) => ({
                    url: API.loginByCertificate,
                    method: 'POST',
                    body,
                }),
                async onQueryStarted(_, { queryFulfilled, dispatch }) {
                    try {
                        await queryFulfilled;
                        dispatch(setAuthenticated(true));
                        localStorage.setItem(LOCAL_STORAGE.authType, String(AuthType.Signature));
                    } catch (error) {
                        console.error(error);
                    }
                },
                invalidatesTags: (_, error) =>
                    error ? [Tags.ENSURE_SESSION, Tags.ESIA_LOGIN_URL] : [Tags.USER_NAME],
            }),
        }),
    });

export const {
    useEnsureSessionMutation,
    useGetEsiaLoginQuery,
    usePostLoginByEsiaMutation,
    usePostLogoutMutation,
    useGetLogoutEsiaUrlMutation,
    useAuthByCertMutation,
    useRefreshTokenMutation,
} = authentication;

import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Spin } from 'antd';

import styles from './esia-redirect.module.css';

import { ROUTES } from '~/app-router/routes';
import { AlertMessage } from '~/constants/alert-message';
import { ESIA_REDIRECT, EsiaCodeDescription } from '~/constants/esia-redirect';
import { stepName } from '~/constants/step-name';
import {
    useEnsureSessionMutation,
    usePostLoginByEsiaMutation,
} from '~/rtk-queries/endpoints/authentication';
import { setPopupAlert } from '~/store/slice/alert/alert-slice';
import { Statuses } from '~/types/common';
import { extractEsiaErrorCode } from '~/utils/extract-esia-error-code';

export const EsiaRedirect = () => {
    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const code = params.get('code') ?? '';
    const state = params.get('state') ?? '';
    const error = params.get('error');
    const errorDescriptionCode = params.get('error_description');

    const [postLoginByEsia, { isError }] = usePostLoginByEsiaMutation();
    const [ensureSession, { data: sessionData, isError: isSessionError }] =
        useEnsureSessionMutation();

    const navigateToStartPage = useCallback(
        () => navigate(`${ROUTES.stepsRootPage}/${stepName.one}`, { replace: true }),
        [navigate],
    );

    useEffect(() => {
        if ((errorDescriptionCode && error) || !code || !state) {
            const message =
                EsiaCodeDescription[extractEsiaErrorCode(errorDescriptionCode)] ??
                EsiaCodeDescription.Default;

            navigateToStartPage();

            dispatch(
                setPopupAlert({
                    type: Statuses.ERROR,
                    message,
                    keepOnRoute: [ROUTES.esiaPage, `${ROUTES.stepsRootPage}/${stepName.one}`],
                }),
            );
        } else {
            ensureSession();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (sessionData) {
            postLoginByEsia({ sessionId: sessionData.sessionId, code, state });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionData, postLoginByEsia]);

    useEffect(() => {
        if (isError || isSessionError) {
            navigateToStartPage();

            dispatch(
                setPopupAlert({
                    type: Statuses.ERROR,
                    message: AlertMessage.ERROR_ESIA,
                    keepOnRoute: [ROUTES.esiaPage, `${ROUTES.stepsRootPage}/${stepName.one}`],
                }),
            );
        }
    }, [isError, isSessionError, dispatch, navigateToStartPage]);

    return (
        <Spin fullscreen={true} tip={ESIA_REDIRECT.tooltipText}>
            <div className={styles.esiaRedirectContainer} />
        </Spin>
    );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
};

export const loaderSlice = createSlice({
    name: 'loader-slice',
    initialState,
    reducers: {
        setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoading = payload;
        },
    },
    selectors: {
        isLoadingSelector: (state) => state.isLoading,
    },
});

export type LoaderState = typeof initialState;
export const { setIsLoading } = loaderSlice.actions;
export const { isLoadingSelector } = loaderSlice.selectors;
export const loaderReducer = loaderSlice.reducer;

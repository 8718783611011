import { useEffect } from 'react';
import { DatePicker, Form } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { useForm, useWatch } from 'antd/es/form/Form';
import { FilterDropdownProps } from 'antd/es/table/interface';
import dayjs, { Dayjs } from 'dayjs';

import { useGetFilters } from '../hooks/use-get-filters';

import { DateContainer } from './date-container';
import { CertificateFiltersForm, CertificateFiltersFormType } from './type';

import styles from './range-picker-dropdown.module.css';

import { buddhistLocale } from '~/constants/date-format';
import { DATE_FORMAT, FORMAT_DATE_1 } from '~/constants/general';
import { CustomRow } from '~/shared/custom-row';
import { CustomForm } from '~/shared/form-components/custom-form';
import { FormSentButtons } from '~/shared/form-components/form-sent-buttons';
import { TableFilter } from '~/types/common';
import { isNotEmptyArray } from '~/utils/is-not-empty-array';

const { RangePicker } = DatePicker;

export const RangePickerDropdown = ({
    confirm,
    setSelectedKeys,
    clearFilters,
    filterName,
}: FilterDropdownProps & { filterName: string }) => {
    const [form] = useForm();
    const currentFilters = useGetFilters(filterName);

    const disabled = !useWatch(CertificateFiltersForm.RANGE_PICKER, form);

    const onCancel = () => {
        form.resetFields();
        clearFilters?.();
    };

    const onCalendarChangeHandler: RangePickerProps['onCalendarChange'] = (dates) => {
        form.setFieldValue(CertificateFiltersForm.RANGE_PICKER, dates);
    };

    const onFinish = (formValues: CertificateFiltersFormType) => {
        const { rangePicker } = formValues;
        const formatRangePickerDate = (date?: Dayjs | null) =>
            date ? dayjs(date).format(FORMAT_DATE_1) : '';

        if (isNotEmptyArray(rangePicker)) {
            const startDate = formatRangePickerDate(rangePicker[0]);
            const endDate = formatRangePickerDate(rangePicker[1]?.endOf('day'));

            setSelectedKeys([startDate, endDate]);
        }
        if (!rangePicker) {
            setSelectedKeys([]);
        }
        confirm();
    };

    useEffect(() => {
        if (currentFilters[TableFilter.lastEditDate]) {
            form.setFieldValue(
                CertificateFiltersForm.RANGE_PICKER,
                currentFilters[TableFilter.lastEditDate]?.map((date: string) =>
                    date ? dayjs.utc(date) : null,
                ),
            );
        }
    }, [currentFilters, form]);

    return (
        <CustomForm
            className={styles.container}
            name='filter-form-range-picker'
            form={form}
            layout='vertical'
            onFinish={onFinish}
        >
            <CustomRow columnSpan={24}>
                <Form.Item name={CertificateFiltersForm.RANGE_PICKER} noStyle={true}>
                    <DateContainer>
                        <RangePicker
                            allowEmpty={[true, true]}
                            popupClassName={styles.range}
                            style={{ height: '40px' }}
                            onCalendarChange={onCalendarChangeHandler}
                            locale={buddhistLocale}
                            format={{
                                format: DATE_FORMAT,
                                type: 'mask',
                            }}
                            getPopupContainer={(node) =>
                                node.closest('#filter-form-range-picker') as HTMLElement
                            }
                        />
                    </DateContainer>
                </Form.Item>
            </CustomRow>
            <FormSentButtons handleCancel={onCancel} disabled={disabled} />
        </CustomForm>
    );
};

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { ROUTES } from '~/app-router/routes';

export const usePrivateRoomUrlParams = () => {
    const location = useLocation();

    const urlRoomSetup = useMemo(() => {
        const params = location.pathname
            .split('/')
            .filter(Boolean)
            .filter((param) => `/${param}` !== ROUTES.privateRoom)
            .map((param) => `/${param}`);

        return { activeRootTab: params[0], roomUrlParams: params };
    }, [location]);

    return { ...urlRoomSetup };
};

import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Alert } from 'antd';

import { Portal } from '../portal';

import styles from './custom-alert.module.css';

import { ALERT_LIFETIME } from '~/constants/alert-message';
import { useAppDispatch, useAppSelector } from '~/hooks';
import { deleteFirstPopupAlert, firstAlertSelector } from '~/store/slice/alert/alert-slice';

export const CustomAlert = () => {
    const location = useLocation();
    const alert = useAppSelector(firstAlertSelector);
    const dispatch = useAppDispatch();

    const handleDeletePopupAlert = useCallback(() => {
        dispatch(deleteFirstPopupAlert());
    }, [dispatch]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;

        if (alert) {
            timeoutId = setTimeout(() => {
                handleDeletePopupAlert();
            }, ALERT_LIFETIME);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [alert, handleDeletePopupAlert]);

    useEffect(() => {
        if (!alert) {
            return;
        }

        const shouldShowPopup = alert.keepOnRoute?.some((route) => location.pathname === route);

        if (!shouldShowPopup) {
            handleDeletePopupAlert();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, handleDeletePopupAlert]);

    if (!alert) {
        return null;
    }

    return (
        <Portal>
            <div className={styles.alertContainer}>
                <Alert
                    key={alert?.message}
                    className={styles.alert}
                    type={alert?.type}
                    showIcon={true}
                    closable={true}
                    message={alert?.message}
                    afterClose={handleDeletePopupAlert}
                />
            </div>
        </Portal>
    );
};

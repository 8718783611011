export const NotificationGosUslugi = {
    start: 'При обнаружении ошибок в данных измените их в',
    linkText: '\u00A0Госуслугах\u00A0',
    link: 'https://www.gosuslugi.ru/',
    end: 'и повторно авторизуйтесь через сервис Госуслуг',
};

export const FormButtonLabel = {
    removeDraft: 'удалить черновик',
};

export const UserInfoApiError = {
    notExist: {
        pattern: "doesn't contain specified template",
        message: 'Удостоверяющий центр больше не выпускает сертификат с таким назначением.',
    },
} as const;

import { MailIcon, ReviewIcon } from '~/components/contact-us/custom-icon/custom-icon';
import { MessageType } from '~/rtk-queries/constants/feedback';

export enum ContactUsTitle {
    Viber = 'Написать в Viber',
    Telegram = 'Написать в Telegram',
    WhatsApp = 'Написать в WhatsApp',
    Mail = 'Письмо в банк',
    Call = 'Онлайн звонок',
    Review = 'Отзыв',
}

export enum ContactUsType {
    feedback = 'feedback',
    mail = 'mail',
}

// https://confluence.services.clevertec.ru/pages/viewpage.action?pageId=71143927

// export enum ContactUsLink {
//     Viber = 'https://chats.viber.com/psbot',
//     Telegram = 'https://t.me/bankPSB_bot',
//     WhatsApp = 'https://wa.me/79646260299',
//     Mail = 'mailto:info@psbank.ru',
//     Call = 'https://www.psbank.ru/call',
// }

// export const CONTACT_US = [

// {
//     title: ContactUsTitle.Viber,
//     link: ContactUsLink.Viber,
//     icon: ViberIcon,
//     keyFrontConfig: FrontConfigKey.ContactLinkViber,
// },
// {
//     title: ContactUsTitle.Telegram,
//     link: ContactUsLink.Telegram,
//     icon: TelegramIcon,
//     keyFrontConfig: FrontConfigKey.ContactLinkTelegram,
// },
// {
//     title: ContactUsTitle.WhatsApp,
//     link: ContactUsLink.WhatsApp,
//     icon: WhatsAppIcon,
//     keyFrontConfig: FrontConfigKey.ContactLinkWhatsApp,
// },
// {
//     title: ContactUsTitle.Mail,
//     link: ContactUsLink.Mail,
//     icon: MailIcon,
//     keyFrontConfig: FrontConfigKey.ContactLinkMail,
// },
// {
//     title: ContactUsTitle.Call,
//     link: ContactUsLink.Call,
//     icon: CallIcon,
//     keyFrontConfig: FrontConfigKey.ContactLinkCall,
// },
// ];

export const MIN_MESSAGE_LENGTH = 3;

export const CONTACT_US = [
    {
        title: ContactUsTitle.Mail,
        icon: MailIcon,
        type: MessageType.mail,
    },
    {
        title: ContactUsTitle.Review,
        icon: ReviewIcon,
        type: MessageType.feedback,
    },
];

enum ContactFormLabel {
    email = 'Ваш Email',
    message = 'Ваше сообщение',
}

export enum ContactUsRulesVariant {
    required = 'Поле обязательно для заполнения',
    email = 'Введите корректный email адрес',
    minLength = `Минимальная длина сообщения ${MIN_MESSAGE_LENGTH} символа`,
}

export const CONTACT_US_MODAL_TITLE = {
    [MessageType.mail]: 'Отправить сообщение в банк',
    [MessageType.feedback]: 'Оставить отзыв о сайте',
    error: 'Ошибка отправки сообщения. Попробуйте еще раз.',
    success: 'Сообщение отправлено.',
};

export const CONTACT_FORM_INITIAL_VALUE = { email: '', message: '' };

export const CONTACT_FORM = [
    {
        name: 'email',
        label: ContactFormLabel.email,
        disabled: false,
        rules: [
            { required: true, message: ContactUsRulesVariant.required },
            { type: 'email', message: ContactUsRulesVariant.email },
        ],
    },
    {
        name: 'message',
        label: ContactFormLabel.message,
        disabled: false,
        rules: [{ required: true, message: ContactUsRulesVariant.required }],
    },
];

import { combineSlices, configureStore } from '@reduxjs/toolkit';
import { CombinedState } from '@reduxjs/toolkit/dist/query/core/apiState';
import { EndpointDefinitions, setupListeners } from '@reduxjs/toolkit/query';

import { logoutMiddleware } from './middleware/logout-middleware';
import { popupAlertsMiddleware } from './middleware/popup-alerts-middleware';
import { alertReducer, alertSlice, AlertState } from './slice/alert/alert-slice';
import { appTabReducer, appTabSlice, AppTabState } from './slice/app-tab/app-tab-slice';
import { authReducer, authSlice, AuthState } from './slice/auth/auth-slice';
import { loaderReducer, loaderSlice, LoaderState } from './slice/loader/loader-slice';
import { modalReducer, modalSlice, ModalState } from './slice/modal/modal-slice';

import { api } from '~/rtk-queries/api';

export type ApplicationState = Readonly<{
    [authSlice.name]: AuthState;
    [appTabSlice.name]: AppTabState;
    [loaderSlice.name]: LoaderState;
    [modalSlice.name]: ModalState;
    [alertSlice.name]: AlertState;
    api: CombinedState<EndpointDefinitions, never, 'api'>;
}>;

const rootReducer = combineSlices({
    [authSlice.name]: authReducer,
    [appTabSlice.name]: appTabReducer,
    [loaderSlice.name]: loaderReducer,
    [modalSlice.name]: modalReducer,
    [alertSlice.name]: alertReducer,
    [api.reducerPath]: api.reducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware, popupAlertsMiddleware, logoutMiddleware),
    devTools: process.env.NODE_ENV !== 'production',
});

setupListeners(store.dispatch);

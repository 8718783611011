import { AlertMessage } from '~/constants/alert-message';
import { api } from '~/rtk-queries/api';
import { API } from '~/rtk-queries/constants/api';
import { transformErrorWithAlert } from '~/rtk-queries/helpers/transform-error-with-alert';
import {
    CheckSignatureDocumentRequest,
    CheckSignatureDocumentResponse,
    CheckVerifyCertificateRequest,
    CheckVerifyCertificateResponse,
    GetSvsPolicy,
} from '~/rtk-queries/types/certificate-verification';
import { CommonResponse } from '~/types/common';

export const certificateVerification = api.injectEndpoints({
    endpoints: (builder) => ({
        getSvsPolicy: builder.query<CommonResponse<GetSvsPolicy>, void>({
            query: (body) => ({
                url: API.getSvsPolicy,
                method: 'GET',
                body,
            }),
            transformErrorResponse: (response) =>
                transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
        }),
        checkVerifyCertificate: builder.mutation<
            CommonResponse<CheckVerifyCertificateResponse>,
            CheckVerifyCertificateRequest
        >({
            query: (data) => ({
                url: `${API.checkVerifyCertificate}`,
                method: 'POST',
                body: data,
            }),
            transformErrorResponse: (response) =>
                transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
        }),
        checkSignatureDocument: builder.mutation<
            CommonResponse<CheckSignatureDocumentResponse>,
            CheckSignatureDocumentRequest
        >({
            query: (data) => ({
                url: `${API.checkSignatureDocuments}`,
                method: 'POST',
                body: data,
            }),
            transformErrorResponse: (response) =>
                transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
        }),
    }),
});

export const {
    useGetSvsPolicyQuery,
    useCheckVerifyCertificateMutation,
    useCheckSignatureDocumentMutation,
} = certificateVerification;

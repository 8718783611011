import { isDayjs } from 'dayjs';

import { trimSpaces } from './trim-spaces';

import { NameAttributes } from '~/rtk-queries/types/certificates';
import { CertificateForm } from '~/types/application-tab/certificate-form';
import { formatStringDate } from '~/utils/format-string-date';

export const prepareCreateRequestBody = (
    formValues: CertificateForm,
    enabledMap: Record<string, boolean>,
): NameAttributes => {
    const body: NameAttributes = {};

    Object.entries(formValues).forEach(([key, value]) => {
        if (!enabledMap[key]) {
            return;
        }

        if (isDayjs(value)) {
            body[key] = formatStringDate(value);

            return;
        }

        if (value === null) {
            body[key] = '';

            return;
        }

        body[key] = typeof value === 'string' ? trimSpaces(value) : value;
    });

    return body;
};

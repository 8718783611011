import { FC, PropsWithChildren } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { ROUTES } from '~/app-router/routes';
import { stepName } from '~/constants/step-name';
import { StepParams } from '~/types/router';

export const StepRedirect: FC<PropsWithChildren> = ({ children }) => {
    const { stepName: currentStep } = useParams<StepParams>();

    const isStepValid = currentStep && Object.values(stepName).includes(currentStep as stepName);

    return isStepValid ? children : <Navigate to={ROUTES.notFound} replace={true} />;
};

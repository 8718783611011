import { FC } from 'react';
import { IMaskInput, IMaskInputProps } from 'react-imask';
import { Form } from 'antd';
import { FormItemProps } from 'antd/es/form';

import styles from './custom-mask-input.module.css';

import { useCustomProps } from '~/hooks/use-custom-props';
import { CustomTooltip } from '~/shared/custom-tooltip';

type CustomInputProps = {
    formItemProps?: FormItemProps;
    inputProps?: IMaskInputProps<HTMLInputElement>;
};

export const CustomMaskInput: FC<CustomInputProps> = ({
    inputProps: { value, ...restInputProps } = {},
    formItemProps = {},
}) => {
    const { titleForTooltip, backgroundColorDisabled } = useCustomProps(
        value,
        restInputProps.disabled || false,
        formItemProps.label,
    );

    const handleFocus = (event: {
        target: { setAttribute: (arg0: string, arg1: string) => void };
    }) => {
        event.target.setAttribute('autocomplete', 'off');
    };

    return (
        <CustomTooltip title={titleForTooltip}>
            <Form.Item className={styles.inputItem} {...formItemProps}>
                <IMaskInput
                    style={backgroundColorDisabled}
                    onFocus={handleFocus}
                    className={styles.inputMask}
                    {...restInputProps}
                />
            </Form.Item>
        </CustomTooltip>
    );
};

import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { Empty } from 'antd';
import classNames from 'classnames';

import { useGetFilters } from '../certificates-table/hooks/use-get-filters';

import { returnColumnsForArchiveTable } from './constants/return-columns-for-archive-table';

import styles from './archive.module.css';

import { ROUTES } from '~/app-router/routes';
import { TABLE_HEIGHT_OFFSET } from '~/constants/certificates-table';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { startObj, useHookForPagination } from '~/hooks/use-hook-for-pagination';
import { usePreventScroll } from '~/hooks/use-prevent-scroll';
import { usePrivateRoomUrlParams } from '~/hooks/use-private-room-url-params';
import {
    useGetCertificatesListQuery,
    useUnarchiveMutation,
} from '~/rtk-queries/endpoints/certificates-list';
import { CertificatesListResponse, SendToArchiveParams } from '~/rtk-queries/types/certificates';
import { CustomTable } from '~/shared/custom-table/custom-table';
import { TableFilters } from '~/types/common';

export const Archive = () => {
    const filtersForArchives = useGetFilters(LOCAL_STORAGE.filtersForArchives);
    const {
        searchParamsObj,
        isEmptyParamObj,
        currentTableSize,
        currentTablePage,
        currentSortOrder,
        currentSortColumn,
    } = useHookForPagination();
    const { activeRootTab } = usePrivateRoomUrlParams();

    const skip = activeRootTab !== ROUTES.archive;

    const bodyForCertificateList = useMemo(() => {
        const { lastEditDate, ...rest } = filtersForArchives as TableFilters;
        const isArchive = true;

        const newFilter = {
            ...rest,
            isArchive,
            ...(lastEditDate?.[0] && { lastEditDateFrom: lastEditDate[0] }),
            ...(lastEditDate?.[1] && { lastEditDateTo: lastEditDate[1] }),
        };

        return { ...newFilter, ...(isEmptyParamObj ? startObj : searchParamsObj) };
    }, [filtersForArchives, isEmptyParamObj, searchParamsObj]);

    const { data: certificatesTableData = {} as CertificatesListResponse, isFetching } =
        useGetCertificatesListQuery(skip ? skipToken : bodyForCertificateList);
    const [unarchiveRequest] = useUnarchiveMutation();

    const { totalCount, currentPage = [], allTitles = [] } = certificatesTableData;

    const columns = returnColumnsForArchiveTable({
        allTitles,
        unarchiveRequest: (params: SendToArchiveParams) => {
            unarchiveRequest(params);
        },
    });

    usePreventScroll(isFetching);

    return (
        <CustomTable
            className={classNames(styles.archiveTable, {
                [styles.emptyTable]: !currentPage.length,
            })}
            dataSource={currentPage}
            columns={columns}
            currentTablePage={currentTablePage}
            currentTableSize={currentTableSize}
            currentSortColumn={currentSortColumn}
            currentSortOrder={currentSortOrder}
            totalCount={totalCount}
            loading={{ spinning: isFetching }}
            rowKey={({ key }) => key}
            locale={{
                emptyText: isFetching ? (
                    <span style={{ display: 'inline-block', height: 100 }} />
                ) : (
                    <Empty description='Нет данных' />
                ),
            }}
            filterName={LOCAL_STORAGE.filtersForArchives}
            scrollX={1300}
            scrollY={`calc(100vh - ${TABLE_HEIGHT_OFFSET}px)`}
        />
    );
};

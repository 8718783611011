import { Navigate, RouteObject } from 'react-router-dom';

import { AuthGuard } from '../guard/require-auth-guard';

import { ROUTES } from '~/app-router/routes';
import { loaderCryptoProPlugin } from '~/app-router/routes/auth/utils/loader-crypto-pro-plugin';
import { stepName } from '~/constants/step-name';
import { EntryPagesLayout } from '~/pages/enter/entry-pages-layout';
import { LazyEnterPage } from '~/pages/enter/lazy-enter-page';
import { EsiaRedirect } from '~/pages/esia-redirect';

export const authRoute: RouteObject = {
    path: ROUTES.startPage,
    children: [
        {
            index: true,
            element: <Navigate to={`${ROUTES.stepsRootPage}/${stepName.one}`} replace={true} />,
        },
        {
            path: ROUTES.stepsRootPage,
            element: (
                <AuthGuard redirectUrl={ROUTES.privateRoom}>
                    <EntryPagesLayout />
                </AuthGuard>
            ),
            children: [
                {
                    path: ROUTES.stepPage,
                    element: <LazyEnterPage />,
                    loader: loaderCryptoProPlugin,
                },
            ],
        },
        {
            path: ROUTES.esiaPage,
            element: (
                <AuthGuard redirectUrl={ROUTES.privateRoom}>
                    <EsiaRedirect />
                </AuthGuard>
            ),
        },
    ],
};

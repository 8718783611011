import { api } from '../api';
import { API } from '../constants/api';
import { transformErrorWithAlert } from '../helpers/transform-error-with-alert';

import { AlertMessage } from '~/constants/alert-message';
import { Tags } from '~/rtk-queries/constants/tags';
import {
    CreateDraftBody,
    DeleteDraftResponse,
    DraftResponse,
} from '~/rtk-queries/types/certificates';
import { CommonResponse } from '~/types/common';

export const certificatesList = api
    .enhanceEndpoints({
        addTagTypes: [Tags.GET_CERTIFICATES_LIST, Tags.GET_DRAFT],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getDraft: builder.query<CommonResponse<DraftResponse>, { RequestId: number }>({
                query: (args) => ({
                    url: API.getDraft,
                    method: 'GET',
                    params: args,
                }),
                providesTags: (_result, _error, arg) => [
                    { type: Tags.GET_DRAFT, id: arg.RequestId },
                ],
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
                keepUnusedDataFor: 0,
            }),

            createDraftRequest: builder.mutation<CommonResponse, CreateDraftBody>({
                query: (body) => ({
                    url: `${API.createDraftRequest}`,
                    method: 'POST',
                    body,
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
                invalidatesTags: (_, error, arg) =>
                    error || arg.requestId ? [] : [Tags.GET_CERTIFICATES_LIST],
            }),

            removeDraft: builder.mutation<
                CommonResponse<DeleteDraftResponse>,
                { RequestId: number }
            >({
                query: (args) => ({
                    url: API.removeDraft,
                    method: 'DELETE',
                    params: args,
                    body: () => ({}),
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
                invalidatesTags: (_, error) => (error ? [] : [Tags.GET_CERTIFICATES_LIST]),
            }),
        }),
    });

export const { useGetDraftQuery, useCreateDraftRequestMutation, useRemoveDraftMutation } =
    certificatesList;

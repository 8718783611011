import { useMemo } from 'react';

import { SignDocumentFields } from '~/components/check-and-sign-certificate/sign-document/types/form-types';

type UseCustomProps = {
    titleForTooltip: string;
    placeholder: string | undefined;
    backgroundColorDisabled: { backgroundColor: string };
};

export const useCustomProps = (
    value: unknown,
    disabled: boolean,
    label?: React.ReactNode,
    itemName?: string,
): UseCustomProps =>
    useMemo(() => {
        const titleForTooltip = typeof value === 'string' && disabled ? value : '';

        let placeholder;

        if (typeof label === 'string') {
            placeholder = label;
        }

        if (disabled) {
            placeholder = undefined;
        }

        if (itemName === SignDocumentFields.thumbprint) {
            placeholder = 'Подпись';
        }

        const backgroundColorDisabled = {
            backgroundColor: disabled ? 'var(--backgroundDisabled)' : '',
        };

        return {
            titleForTooltip,
            placeholder,
            backgroundColorDisabled,
        };
    }, [value, disabled, label, itemName]);
